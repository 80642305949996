import mixpanel from "mixpanel-browser";

export const logBuyMysteryBox = (amount: number) => {
  mixpanel.track("buy mystery box", { amount });
};

export const logOpenMysteryBox = () => {
  mixpanel.track("open mystery box");
};

export const logBuyMysteryBoxFinished = (success: boolean) => {
  mixpanel.track("buy mystery box finished", { success });
};

export const logOpenMysteryBoxFinished = (success: boolean) => {
  mixpanel.track("open mystery box finished", { success });
};

export const logKAKAPageView = () => {
  mixpanel.track("KAKA Page View");
};

export const logDetailPageView = () => {
  mixpanel.track("Detail Page View");
};

export const logFearPageView = () => {
  mixpanel.track("Fear Page View");
};
