import Button, { ButtonVariant } from "@Components/Button";
import { getCurrentChainId } from "dodo-wallet";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { MysteryBoxContractAddress, MysteryBoxType } from "./actions";
import { Backdrop, CloseButton, ThumbnailWrapper } from "./styled";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import Close from "../../assets/close.png";
import { message } from "antd";
interface Props {
  show: boolean;
  onClose: () => void;
  tokenID: string;
  imgUrl: string;
  type: MysteryBoxType;
}

export default function ExportNFT({
  show,
  onClose,
  tokenID,
  imgUrl,
  type,
}: Props) {
  const chainId = useSelector(getCurrentChainId);
  const contractAddress = useMemo(
    () => MysteryBoxContractAddress[type][chainId],
    [type, chainId]
  );
  const { t } = useTranslation();
  const variant = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return ButtonVariant.Yellow;
    else if (type === MysteryBoxType.Fear) return ButtonVariant.Default;
    else return ButtonVariant.Purple;
  }, [type]);
  return (
    <Backdrop show={show} style={{ background: "rgba(0, 0, 0, 0.95)" }}>
      <ThumbnailWrapper
        type={type}
        style={{ width: 448, padding: "80px 60px 48px" }}
      >
        <CloseButton onClick={onClose}>
          <img width="40" src={Close} />
        </CloseButton>
        <div style={{ position: "relative", zIndex: 0 }}>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12, marginBottom: 10 }}>Token ID</div>
            <div style={{ fontSize: 16 }}>{tokenID}</div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12, marginBottom: 10 }}>
              {t("mystery-box.export-nft.chain-name")}
            </div>
            <div style={{ fontSize: 16 }}>BSC</div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12, marginBottom: 10 }}>
              {t("mystery-box.export-nft.contract-address")}
            </div>
            <div style={{ fontSize: 12 }}>{contractAddress}</div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              onClick={() => {
                copy(contractAddress);
                message.info(t("common.copy-success"));
              }}
              style={{ width: 262, margin: "54px auto 0" }}
              variant={variant}
            >
              {t("common.copy-address")}
            </Button>
          </div>
        </div>
      </ThumbnailWrapper>
    </Backdrop>
  );
}
