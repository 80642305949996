import JsSHA from "jssha";

// Helper for URL generator
function hex2a(hexx) {
  var hex = hexx.toString(); //force conversion
  var str = "";
  for (var i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

export interface GeneratorOpts {
  enlarge?: 0 | 1;
  extension?: "jpg" | "png" | "webp";
  gravity?: "no" | "so" | "ea" | "we" | "ce" | "sm";
  height: string;
  key?: string;
  proxyUrl?: string;
  resize?: string;
  salt?: string;
  url: string;
  width: string;
}

// URL generator logic
export function generateProxyUrl({
  resize = "fit",
  width,
  height,
  gravity = "no",
  enlarge = 0,
  extension = "png",
  key = "",
  salt = "",
  url,
  proxyUrl = "https://cmp.dodoex.io",
}: GeneratorOpts) {
  var encodedUrl = btoa(url)
    .replace(/=/g, "")
    .replace(/\//g, "_")
    .replace(/\+/g, "-");
  var path =
    "/rs:" +
    resize +
    ":" +
    width +
    ":" +
    height +
    ":" +
    enlarge +
    "/g:" +
    gravity +
    "/" +
    encodedUrl +
    "." +
    extension;
  var shaObj = new JsSHA("SHA-256", "BYTES");
  shaObj.setHMACKey(key, "HEX");
  shaObj.update(hex2a(salt));
  shaObj.update(path);
  var hmac = shaObj
    .getHMAC("B64")
    .replace(/=/g, "")
    .replace(/\//g, "_")
    .replace(/\+/g, "-");
  return proxyUrl + "/" + hmac + path;
}
