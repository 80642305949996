import {
  Flex,
  FormCard,
  InputGroup,
  NumberAdder,
  PurpleFont,
  SubmitButton,
  TextFont,
} from "./styled";
import {
  MysteryBoxTimestamps,
  MysteryBoxType,
  useWriteContract,
} from "./actions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getCurrentAccount, getEthBalance } from "dodo-wallet";
import { logBuyMysteryBox, logBuyMysteryBoxFinished } from "../../lib/mixpanel";

import BigNumber from "bignumber.js";
import { ButtonVariant } from "@Components/Button";
import BuySuccess from "./BuySuccess";
import CounterDown from "./CounterDown";
import RightArrow from "../../assets/rightArrow.png";
import { RootState } from "src/store";
import { formatReadableNumber } from "@Utils/formatter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface Props {
  currentPrice: BigNumber;
  currentSellingTickets: number;
  refetch: () => void;
  refetchTicketsCount: () => void;
  type: MysteryBoxType;
}

export default function Form({
  currentPrice,
  currentSellingTickets,
  refetch,
  refetchTicketsCount,
  type,
}: Props) {
  const [amount, setAmount] = useState<number>(1);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [buyFinished, setBuyFinished] = useState<boolean>(false);
  const [buyError, setBuyError] = useState<boolean>(false);
  const account = useSelector(getCurrentAccount);
  const balance = useSelector((state: RootState) =>
    getEthBalance(account, state)
  );
  const { buyTicket, loading } = useWriteContract(type);
  const { t } = useTranslation();
  const handleSubmit = useCallback(async () => {
    if (amount > currentSellingTickets) return;
    logBuyMysteryBox(amount);
    try {
      const result = await buyTicket(currentPrice.times(amount), (ret) => {
        if (ret) {
          setBuyFinished(true);
          refetch();
          refetchTicketsCount();
        } else {
          setBuyError(true);
        }
        logBuyMysteryBoxFinished(!!ret);
      });
      if (result) setShowSuccess(true);
    } catch (e) {
      console.log("Buy Ticket Error:", e);
    }
  }, [amount, currentPrice, currentSellingTickets, refetch]);

  const startTime = MysteryBoxTimestamps[type].startTime;
  const endTime = MysteryBoxTimestamps[type].endTime;
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  useEffect(() => {
    const timer = window.setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => window.clearInterval(timer);
  }, []);

  const variant = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return ButtonVariant.Yellow;
    else if (type === MysteryBoxType.Fear) return ButtonVariant.Default;
    else return ButtonVariant.Purple;
  }, [type]);

  return (
    <>
      <BuySuccess
        type={type}
        finished={buyFinished}
        error={buyError}
        show={showSuccess}
        onClose={() => {
          setShowSuccess(false);
          setBuyFinished(false);
        }}
      />
      <FormCard type={type}>
        <TextFont
          opacity
          style={{
            textAlign: "left",
            fontSize: 12,
            marginBottom: 8,
            width: "100%",
          }}
          type={type}
        >
          {t("mystery-box.form.stock")}
          {t("colon")}
          {formatReadableNumber({
            input: currentSellingTickets,
            showDecimals: 0,
          })}
        </TextFont>
        <InputGroup type={type}>
          <TextFont type={type}>{t("mystery-box.form.amount")}</TextFont>
          <Flex style={{ zIndex: 1 }}>
            <NumberAdder
              _type={type}
              disabled={amount <= 1}
              onClick={() => setAmount((a) => a - 1)}
            >
              -
            </NumberAdder>
            <input
              value={amount}
              onChange={(e) => {
                const value = e.target.value;
                setAmount(Number(value));
              }}
            />
            <NumberAdder
              _type={type}
              disabled={amount >= currentSellingTickets}
              onClick={() => setAmount((a) => a + 1)}
            >
              +
            </NumberAdder>
          </Flex>
        </InputGroup>
        <Flex
          style={{
            marginBottom: 8,
            marginTop: 40,
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TextFont
            type={type}
            opacity
            style={{
              textAlign: "left",
              fontSize: 12,
            }}
          >
            {t("common.balance")}
            {t("colon")}
            {formatReadableNumber({ input: balance, showDecimals: 2 })}
          </TextFont>
          <a
            href="https://app.dodoex.io/exchange/BUSD-BNB?network=bsc-mainnet"
            target="_blank"
            style={{
              width: "auto",
              paddingRight: 0,
              display: "flex",
              alignItems: "center",
              fontSize: 12,
              color: "#242428",
            }}
          >
            {t("mystery-box.form.buy-bnb")}{" "}
            <img src={RightArrow} style={{ marginLeft: 4 }} />
          </a>
        </Flex>
        <InputGroup type={type}>
          <TextFont type={type}>{t("mystery-box.form.pay-amount")}</TextFont>
          <PurpleFont bold style={{ fontSize: 18 }} type={type}>
            {formatReadableNumber({
              input: currentPrice.times(amount),
              showDecimals: 2,
            })}{" "}
            BNB
          </PurpleFont>
        </InputGroup>
        {currentTime < startTime ? (
          <SubmitButton variant={variant} disabled>
            <CounterDown sec={startTime - currentTime} />
          </SubmitButton>
        ) : (
          <SubmitButton
            variant={variant}
            onClick={handleSubmit}
            disabled={amount < 1 || currentTime >= endTime || loading}
          >
            {loading
              ? t("common.confirm-wallet")
              : t("mystery-box.form.submit-button")}
          </SubmitButton>
        )}
      </FormCard>
    </>
  );
}
