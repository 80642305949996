import React from "react";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "../actions";
import { Flex, TextFont } from "../styled";

export default function BoxInfoRankList() {
  const { t } = useTranslation();
  return (
    <Flex style={{ marginBottom: 20 }}>
      <div style={{ marginRight: 110 }}>
        <TextFont
          type={MysteryBoxType.YuiHatano}
          opacity
          style={{ marginRight: 8 }}
        >
          {t("mystery-box.box-info.Rank6")}
          {t("colon")}1200
        </TextFont>
        <TextFont
          type={MysteryBoxType.YuiHatano}
          opacity
          style={{ marginRight: 8 }}
        >
          {t("mystery-box.box-info.Rank5")}
          {t("colon")}800
        </TextFont>
        <TextFont type={MysteryBoxType.YuiHatano} opacity>
          {t("mystery-box.box-info.Rank4")}
          {t("colon")}700
        </TextFont>
      </div>
      <div>
        <TextFont
          type={MysteryBoxType.YuiHatano}
          opacity
          style={{ marginRight: 8 }}
        >
          {t("mystery-box.box-info.Rank3")}
          {t("colon")}200
        </TextFont>
        <TextFont
          type={MysteryBoxType.YuiHatano}
          opacity
          style={{ marginRight: 8 }}
        >
          {t("mystery-box.box-info.Rank2")}
          {t("colon")}90
        </TextFont>
        <TextFont type={MysteryBoxType.YuiHatano} opacity>
          {t("mystery-box.box-info.Rank1")}
          {t("colon")}10
        </TextFont>
      </div>
    </Flex>
  );
}
