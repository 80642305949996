import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

/* tslint:disable */
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  onClick?: any;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
}

const BasicButton: FunctionComponent<Props> = React.memo(
  ({ onClick, selected, disabled, className, children, ...rest }) => {
    const componentIsMounted = useRef(true);
    useEffect(() => {
      return () => {
        componentIsMounted.current = false;
      };
    }, []);
    const deferredAnimation: React.MutableRefObject<number | null> = useRef(
      null
    );
    const queueAnimation = (action) => {
      if (deferredAnimation.current !== null) {
        clearTimeout(deferredAnimation.current);
      }

      deferredAnimation.current = window.setTimeout(() => {
        deferredAnimation.current = null;
        if (componentIsMounted.current) {
          action(queueAnimation);
        }
      }, 200);
    };

    const [style, setStyle] = useState("default");

    const onClickWrapped = useCallback(
      (e) => {
        if (e) {
          e.preventDefault();
        }

        if (disabled) return;
        if (onClick) onClick();
        setStyle("active");
        queueAnimation((queue) => {
          setStyle("out");
          queue(() => {
            setStyle("default");
          });
        });
      },
      [onClick, disabled]
    );

    const onMouseDown = useCallback(() => {
      if (disabled) return;
      setStyle("pressed");
    }, [onClick, disabled]);

    const onMouseOut = useCallback(() => {
      if (disabled) return;
      setStyle("out");

      queueAnimation(() => {
        setStyle("default");
      });
    }, [onClick, disabled]);
    //jsx-a11y/mouse-events-have-key-events
    return (
      // eslint-disable-next-line
      <button
        type="button"
        className={clsx(style, { selected }, className)}
        onMouseDown={onMouseDown}
        onMouseOut={onMouseOut}
        onTouchStart={onMouseDown}
        onTouchCancel={onMouseOut}
        onClick={onClickWrapped}
        disabled={disabled}
        style={disabled ? { pointerEvents: "none" } : {}}
      >
        {children}
      </button>
    );
  }
);
BasicButton.displayName = "WithMemoBasicButton";

export default styled(BasicButton)`
  ${/* background: ${({ theme }) => theme.palette.buttonBackground}; */ null}
  ${/* color: ${({ theme }) => theme.palette.buttonText}; */ null}
  --bg: #CCC;
  --fg: black;

  background: var(--bg);
  color: var(--fg);

  padding: 5px 12px;
  font-size: 14px;
  border: 0px;
  border-radius: 10px;
  white-space: nowrap;
  height: 36px;
  border: none;
  appearance: none;
  cursor: pointer;
  border: none;
  outline: 0;
  box-shadow: none;
  transition: ease background-color 250ms, ease color 250ms;

  &:focus,
  &:active {
    border: none;
    outline: 0;
    background: var(--bg);
    color: var(--fg);
  }

  &.selected,
  &:hover:enabled {
    border: none;
    outline: 0;
    background: var(--bg);
    color: var(--fg);
  }

  &:disabled {
    background: var(--bg);
    color: var(--fg);
    cursor: default;
  }

  &.default {
    box-shadow: none;
  }
`;
