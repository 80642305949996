import Background from "../../../assets/background.png";
import { Layout, Menu } from "antd";
import styled from "styled-components";
import Button from "@Components/Button";

const { Header, Content } = Layout;

export const DLayout = styled(Layout)`
  background: url("${Background}") space center;
  background-size: 113px auto;
  background-color: #fae4e8;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`;

export const DHeader = styled(Header)`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  background: transparent;
  img {
    width: 121px;
  }
  ${Button} {
    margin-left: 24px;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 100px;
    }
    ${Button} {
      margin-left: 4px;
    }
  }
`;

export const Spanner = styled.div`
  flex: 1;
`;

export const DContent = styled(Content)`
  color: #fff;
  font-size: 14px;
  flex: 1;
  min-height: 100vh;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const LanguageMenu = styled(Menu)`
  width: 160px;
`;
