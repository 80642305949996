import kakaBackgroundImage from "./images/kaka-drops-background.png";
import kakaLogo from "./images/kaka-logo.png";
import fearLogo from "./images/fear-logo.png";
import nftOBabyLogo from "./images/nftOBaby-logo.png";
import yuiBackgroundImage from "./images/yui-drops-background.png";
import fearBackgroundImage from "./images/fear-dodo-background.png";
export const oldDropsConfig = [
  {
    id: "fear",
    title: "mystery-box.box-info.title.fear",
    background: fearBackgroundImage,
    officialName: "Fear",
    logoImage: fearLogo,
    startDate: "2021-06-07T11:00:00Z",
    endDate: "2021-06-09T11:00:00Z",
    hot: true,
  },
  {
    id: "kaka",
    title: "mystery-box.box-info.title.kaka",
    background: kakaBackgroundImage,
    officialName: "KAKA",
    logoImage: kakaLogo,
    startDate: "2021-05-28T13:00:00Z",
    endDate: "2021-05-30T13:00:00Z",
  },
  {
    id: "yui-hatano",
    title: "mystery-box.box-info.title",
    background: yuiBackgroundImage,
    officialName: "NFTOBaby",
    logoImage: nftOBabyLogo,
    // new Date() UTC 时间 北京时间=UTC+8 时区：Asia/Hong_Kong
    startDate: "2021-05-06T13:00:00Z",
    endDate: "2021-05-10T12:00:00Z",
  },
];
