import React from "react";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "../actions";
import { Flex, TextFont } from "../styled";

export default function BoxInfoRankList() {
  const { t } = useTranslation();
  return (
    <Flex style={{ marginBottom: 20, flexWrap: "wrap", lineHeight: "32px" }}>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Venom")}
        {t("colon")}3
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Black Panther")}
        {t("colon")}5
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Thor")}
        {t("colon")}278
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Iron Man")}
        {t("colon")}293
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Captain America")}
        {t("colon")}293
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Spider-Man")}
        {t("colon")}615
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Hawkeye")}
        {t("colon")}700
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Black Widow")}
        {t("colon")}705
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.War Machine")}
        {t("colon")}710
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Hulk")}
        {t("colon")}735
      </TextFont>
      <TextFont
        type={MysteryBoxType.KAKA}
        style={{ marginRight: 8, minWidth: 150 }}
      >
        {t("mystery-box.box-info.Ant-Man")}
        {t("colon")}1000
      </TextFont>
    </Flex>
  );
}
