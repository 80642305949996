import Button, { ButtonVariant } from "@Components/Button";
import styled from "styled-components";
import { MysteryBoxType } from "./actions";

export const PurpleColor = "#f87abd";
export const YellowColor = "rgb(233, 253, 83)";
export const BlueColor = "rgb(7, 15, 65)";
export const BlackColor = "#242428";
export const HighlightYellowColor = "#FFE804";

export const BuyCard = styled.div<{ type?: MysteryBoxType }>`
  max-width: 1440px;
  margin: auto;
  position: relative;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(98, 55, 168, 0.1);
  color: ${({ type }) => {
    if (type === MysteryBoxType.Fear) return "#fff";
    else return "#111";
  }};
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: ${({ type }) => {
      if (type === MysteryBoxType.YuiHatano) return "0.9";
      else return "1";
    }};
    background: ${({ type }) => {
      if (type === MysteryBoxType.Fear) return BlackColor;
      else return "#fff";
    }};
    z-index: 0;
  }
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    z-index: 0;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const BoxInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  border-right: 2px dashed #bdbbbc;
  z-index: 1;
  min-height: 398px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    border-right: 0;
    border-bottom: 2px dashed #bdbbbc;
  }
`;

export const BoxInfoContent = styled.div`
  margin-left: 50px;
  flex: 1;
  @media screen and (max-width: 767px) {
    > * {
      padding: 0 16px;
    }
    margin-left: 0;
  }
`;

export const PurpleFont = styled.div<{ bold?: boolean; type?: MysteryBoxType }>`
  color: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return YellowColor;
    else if (type === MysteryBoxType.Fear) return HighlightYellowColor;
    else return PurpleColor;
  }};
  ${({ bold }) => (bold ? "font-weight: bold;" : "")}
`;
export const GreenFont = styled.div<{ bold?: boolean }>`
  color: #2eae61;
  ${({ bold }) => (bold ? "font-weight: bold;" : "")}
`;

export const TextFont = styled.div<{
  bold?: boolean;
  opacity?: boolean;
  type?: MysteryBoxType;
}>`
  color: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return BlueColor;
    else if (type === MysteryBoxType.Fear) return "#fff";
    else return "#111";
  }};
  ${({ bold }) => (bold ? "font-weight: bold;" : "")}
  ${({ opacity }) => (opacity ? "opacity: 0.7;" : "")}
  display: flex;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
`;

export const PriceWrapper = styled.div<{ type?: MysteryBoxType }>`
  position: relative;
  padding: 10px;
  margin-bottom: 24px;
  min-width: 415px;
  max-width: 90%;
  display: flex;
  align-items: center;
  height: 58px;
  background: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return BlueColor;
    if (type === MysteryBoxType.Fear) return "rgba(255, 255, 255, 0.05)";
    else return "#fdeaf4";
  }};
  color: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return "#fff";
    else if (type === MysteryBoxType.Fear) return "#fff";
    else return "#111";
  }};
  border-radius: 4px;
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  & > * {
    flex: 1;
    margin: 0 16px;
    display: flex;
    align-items: center;
    border-right: 1px solid
      ${({ type }) => {
        if (type === MysteryBoxType.KAKA) return "#fff";
        else return "rgba(255, 255, 255, 0.08)";
      }};
    &:last-child {
      border-right: 0;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 767px) {
    width: calc(100vw - 32px);
    min-width: auto;
    max-width: 100%;
    & > * {
      margin: 0;
      flex: auto;
      border-right: none;
    }
  }
`;

export const FormCard = styled.div<{ type?: MysteryBoxType }>`
  width: 380px;
  max-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: -20px;
    top: -20px;
    background: ${({ type }) => {
      if (type === MysteryBoxType.KAKA) return BlueColor;
      else if (type === MysteryBoxType.Fear) return "#151516";
      else return "#fae4e8";
    }};
  }
  &:after {
    content: " ";
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: -20px;
    bottom: -20px;
    background: ${({ type }) => {
      if (type === MysteryBoxType.KAKA) return BlueColor;
      else if (type === MysteryBoxType.Fear) return "#151516";
      else return "#fae4e8";
    }};
  }
  @media screen and (max-width: 767px) {
    padding: 24px;
    &:after {
      left: auto;
      right: -20px;
      bottom: auto;
      top: -20px;
    }
  }
`;

export const InputGroup = styled.div<{ type?: MysteryBoxType }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  height: 90px;
  position: relative;
  background: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return "rgb(180, 183, 193)";
    else if (type === MysteryBoxType.Fear) return "rgba(255, 255, 255, 0.05)";
    else return "transparent";
  }};
  border-radius: 8px;
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ type }) => {
      if (type === MysteryBoxType.KAKA) return "#fff";
      else if (type === MysteryBoxType.Fear) return "rgba(255, 255, 255, 0.05)";
      else return "#ffb2db";
    }};
    opacity: 0.2;
    border-radius: 8px;
    z-index: 0;
  }
  input {
    background: transparent;
    border: none;
    font-size: 18px;
    font-weight: bold;
    width: 54px;
    color: ${({ type }) => {
      if (type === MysteryBoxType.Fear) return "#fff";
      else return "#111";
    }};
    text-align: center;
    &:focus,
    &:active {
      outline: none;
    }
  }
`;

export const NumberAdder = styled.button<{ _type?: MysteryBoxType }>`
  background: ${({ _type }) => {
    if (_type === MysteryBoxType.KAKA) return BlueColor;
    else if (_type === MysteryBoxType.Fear) return "#fff";
    else return "#ff50f1";
  }};
  color: ${({ _type }) => {
    if (_type === MysteryBoxType.Fear) return "#111";
    else return "#fff";
  }};
  width: 26px;
  height: 26px;
  line-height: 26px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  outline: none;
  box-shadow: none;
  border: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 24px;
  border-radius: 24px;
  width: 100%;
  height: 48px;
`;

export const MineWrapper = styled.div<{ type?: MysteryBoxType }>`
  margin: 0 auto;
  max-width: 1440px;
  box-shadow: 0px 4px 20px 0px rgba(98, 55, 168, 0.1);
  position: relative;
  padding: 56px 140px;
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: ${({ type }) => {
      if (type === MysteryBoxType.YuiHatano) return "0.9";
      else return "1";
    }};
    background: ${({ type }) => {
      if (type === MysteryBoxType.Fear) return BlackColor;
      else return "#fff";
    }};
    z-index: 0;
    border-radius: 8px;
  }
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    z-index: 0;
  }
`;

export const ThumbnailWrapper = styled.div<{ type: MysteryBoxType }>`
  width: 320px;
  max-width: 100vw;
  border-radius: 8px;
  margin: 16px;
  border 1px solid ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return YellowColor;
    else if (type === MysteryBoxType.Fear) return "rgba(255, 255, 255, 0.1)";
    else return "#FFA3FA";
  }};
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(98, 55, 168, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return BlueColor;
    else if (type === MysteryBoxType.Fear) return BlackColor;
    else return "#fff";
  }};
  color: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return "#fff";
    else if (type === MysteryBoxType.Fear) return "#fff";
    else return "#000";
  }};
`;

export const DialogWrapper = styled.div`
  width: 448px;
  max-width: 100%;
  padding: 80px 60px 48px;
  border-radius: 8px;
  margin: 16px;
  border 1px solid #FFA3FA;
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(98, 55, 168, 0.1);
  display: flex;
  flex-direction: column;
  background: linear-gradient(315deg, #39326B 0%, #743879 100%);
`;

export const ThumbnailImgWrapper = styled.div`
  position: relative;
  z-index: 0;
  padding: 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 272px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  img,
  video {
    position: absolute;
    width: 100%;
    max-height: 352px;
  }
`;

export const Backdrop = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.95);
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const MenuButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 10px;
  background: #fff;
  border-radius: 100%;
  padding: 0 4px;
  img {
    width: 18px;
  }
`;

export const ExportButton = styled.div<{ show: boolean }>`
  cursor: pointer;
  width: 118px;
  opacity: 0.9;
  height: 44px;
  align-items: center;
  color: #242428;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 0px rgba(36, 36, 40, 0.15);
  position: absolute;
  top: 100%;
  right: 50%;
  display: ${({ show }) => (show ? "flex" : "none")};
`;

export const OutlineButton = styled(Button)<{ _type: MysteryBoxType }>`
  ${({ variant, _type }) => {
    if (variant !== ButtonVariant.White) return "";
    if (_type === MysteryBoxType.KAKA)
      return "border: 1px solid rgb(7, 15, 65); background: transparent;";
    else if (_type === MysteryBoxType.Fear)
      return "border: 1px solid rgba(255, 255, 255, 0.1); background: transparent; color: #fff;";
    else return "border: 1px solid #FFB2DB; background: transparent;";
  }}
  min-width: 100px;
`;
