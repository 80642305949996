import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import React from "react";
import styled from "styled-components";

export enum ButtonVariant {
  Default = "DEFAULT",
  Highlighted = "HIGHLIGHTED",
  Black = "BLACK",
  Purple = "Purple",
  White = "White",
  Yellow = "Yellow",
  Blue = "Blue",
}

const BACKGROUND: Record<ButtonVariant, string> = {
  [ButtonVariant.Default]: "#FFE804",
  [ButtonVariant.Highlighted]: "#2689FF",
  [ButtonVariant.Black]: "#000000",
  [ButtonVariant.Purple]: "#F87ABD",
  [ButtonVariant.White]: "#ffffff",
  [ButtonVariant.Yellow]: "rgb(233, 253, 83)",
  [ButtonVariant.Blue]: "rgb(7, 15, 65)",
};

const FOREGROUND: Record<ButtonVariant, string> = {
  [ButtonVariant.Default]: "black",
  [ButtonVariant.Highlighted]: "white",
  [ButtonVariant.Black]: "white",
  [ButtonVariant.Purple]: "white",
  [ButtonVariant.White]: "black",
  [ButtonVariant.Yellow]: "black",
  [ButtonVariant.Blue]: "rgb(233, 253, 83)",
};

type Props = {
  variant?: ButtonVariant;
};

const Button = styled.button<Props>`
  min-width: 110px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: ${({ variant = ButtonVariant.Default }) => BACKGROUND[variant]};
  color: ${({ variant = ButtonVariant.Default }) => FOREGROUND[variant]};
  font-size: 14px;
  font-weight: 600;

  border: none;
  outline: 0;

  border-radius: 20px;

  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default Button;
