import { Backdrop, CloseButton, GreenFont, ThumbnailWrapper } from "./styled";
import Button, { ButtonVariant } from "@Components/Button";

import BoxCover from "../../assets/box-cover.png";
import BoxCoverKAKA from "../../assets/box-cover-kaka.png";
import BoxCoverFearVideo from "../../assets/blindbox.mp4";
import Close from "../../assets/close.png";
import React, { useMemo } from "react";
import Waiting, { WaitingAction } from "./Waiting";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "./actions";

interface Props {
  show: boolean;
  onClose: () => void;
  finished: boolean;
  type: MysteryBoxType;
  error: boolean;
}

export default function BuySuccess({
  show,
  onClose,
  finished,
  type,
  error,
}: Props) {
  const { t } = useTranslation();
  const variant = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return ButtonVariant.Yellow;
    else if (type === MysteryBoxType.Fear) return ButtonVariant.Default;
    else return ButtonVariant.Purple;
  }, [type]);
  const image = useMemo(() => {
    if (type === MysteryBoxType.KAKA)
      return <img width="240" src={BoxCoverKAKA} />;
    else if (type === MysteryBoxType.Fear)
      return (
        <video
          style={{ width: 240 }}
          src={BoxCoverFearVideo}
          loop
          autoPlay
          muted
          playsInline
          x5-video-player-fullscreen
          x5-playsinline
          webkit-playsinline
        ></video>
      );
    else return <img width="240" src={BoxCover} />;
  }, [type]);
  return (
    <Backdrop show={show}>
      <ThumbnailWrapper
        type={type}
        style={{ width: 448, padding: "44px 0 34px" }}
      >
        <CloseButton onClick={onClose}>
          <img width="40" src={Close} />
        </CloseButton>
        {finished ? (
          <div style={{ position: "relative", zIndex: 0, textAlign: "center" }}>
            {image}
            <GreenFont style={{ marginTop: 28, marginBottom: 8, fontSize: 18 }}>
              {t("mystery-box.buy-success.success")}
            </GreenFont>
            <div style={{ marginBottom: 28, justifyContent: "center" }}>
              {t("mystery-box.buy-success.tip")}
            </div>
            <Button onClick={onClose} style={{ width: 180 }} variant={variant}>
              {t("common.confirm")}
            </Button>
          </div>
        ) : error ? (
          t("mystery-box.buy-success.failed")
        ) : (
          <Waiting
            show={show}
            action={WaitingAction.Buy}
            type={type}
            text={t("mystery-box.buy-success.loading")}
          />
        )}
      </ThumbnailWrapper>
    </Backdrop>
  );
}
