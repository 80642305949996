import { Button } from "antd";
import styled from "styled-components";
import topBackground from "./images/background.png";

export const Container = styled.div`
  background-color: #151516;
  min-height: 100vh;
  height: 100%;
`;

export const TopWrapper = styled.div`
  height: 590px;

  @media screen and (max-width: 767px) {
    margin: 24px 16px 0px;
    border-radius: 10px;
    height: 200px;
  }
`;

export const Top = styled.div`
  max-width: 1440px;
  margin: auto;
  position: relative;
  video {
    position: absolute;
    z-index: 0;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    video {
      height: 200px;
    }
  }
`;

export const HotTitle = styled.div`
  margin-left: 72px;
  padding-top: 151px;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 767px) {
    margin-left: 16px;
    padding-top: 66px;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }
`;

export const ImmediatelyBuyButton = styled(Button)`
  margin-left: 72px;
  margin-top: 31px;
  border-radius: 23px;
  &,
  &:hover,
  &:active,
  &:focus {
    color: #242428;
    border: 1px solid #ffe804;
    background: #ffe804;
  }

  @media screen and (max-width: 767px) {
    margin-left: 16px;
    margin-top: 16px;
    border-radius: 23px;
  }
`;

export const Center = styled.div`
  margin: 8px auto 144px;
  padding-bottom: 144px;
  max-width: 1376px;

  @media screen and (max-width: 767px) {
    margin: 0px 16px;
    padding-bottom: 0px;
    max-width: 100%;
  }
`;
