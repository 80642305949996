import copy from "copy-to-clipboard";
import { message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Copy } from "../assets/Copy";
import styled from "styled-components";
import { truncatePoolAddress } from "../utils";

const Address = styled.span`
  margin-right: 6px;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const Link = styled.div<{ size?: "small" | "default" | "big"; color?: string }>`
  display: flex;
  align-items: center;
  font-size: ${({ size = "default" }) =>
    size === "small" ? "12px" : size === "big" ? "20px" : "14px"};
  line-height: ${({ size = "default" }) =>
    size === "small" ? "17px" : size === "big" ? "28px" : "20px"};
  height: ${({ size = "default" }) =>
    size === "small" ? "17px" : size === "big" ? "28px" : "20px"};
  justify-content: flex-start;

  cursor: pointer;

  ${Address} {
    line-height: ${({ size = "default" }) =>
      size === "small" ? "15px" : size === "big" ? "26px" : "18px"};
    height: ${({ size = "default" }) =>
      size === "small" ? "15px" : size === "big" ? "26px" : "18px"};
  }
`;

type IconSize = "smaller" | "small" | "default";
const IconWrapper = styled.div<{ hiddenBg?: boolean; size?: IconSize }>`
  display: flex;
  align-items: center;
  margin-left: 2px;
  border-radius: 50%;
  > svg {
    width: ${({ size = "smaller" }) =>
      size === "smaller" ? "14px" : size === "small" ? "14px" : "16px"};
    height: ${({ size = "smaller" }) =>
      size === "smaller" ? "14px" : size === "small" ? "14px" : "16px"};
  }
  cursor: pointer;
  &:hover {
    background: ${({ hiddenBg = false }) =>
      hiddenBg ? "transparent" : "#414143"};
  }

  /* 同时显示复制和跳转按钮时增加间距 */
  & + & {
    margin-left: 8px;
  }
`;

interface Props {
  address: string;
  truncate?: boolean;
  size?: "small" | "default" | "big";
  color?: string;
  iconSize?: IconSize;
}

export const AddressWithArrowLink: React.FC<Props> = ({
  address,
  truncate = true,
  size = "default",
  color,
  iconSize,
}: Props) => {
  const { t } = useTranslation();
  const handleCopy = (evt) => {
    evt.stopPropagation();
    copy(address);
    message.info(t("common.copy-success"));
  };

  return (
    <Link size={size} color={color}>
      <Address onClick={handleCopy}>
        {truncate ? truncatePoolAddress(address) : address}
      </Address>
      <IconWrapper onClick={handleCopy} size={iconSize}>
        <Copy />
      </IconWrapper>
    </Link>
  );
};
