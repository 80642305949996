import BigNumber from "bignumber.js";
import Web3 from "web3";
import numeral from "numeral";

export const shortAddress = (data: string): string => {
  return data && data.slice ? data.slice(0, 6) + "..." + data.slice(-4) : data;
};

export const formatAddress = (address: string): string => {
  try {
    return Web3.utils.toChecksumAddress(address);
  } catch (error) {
    // console.log(error);
    return "";
  }
};

export const formatBalance = (
  amount: BigNumber | string | number,
  showDecimals: number,
  autoUnit = false
): string => {
  amount = new BigNumber(amount);
  if (autoUnit && amount.gt(1000)) {
    return numeral(amount.toFormat(showDecimals, BigNumber.ROUND_DOWN)).format(
      "0.00a"
    );
  }

  return amount.toFormat(showDecimals, BigNumber.ROUND_DOWN);
};

export const formatApy = (amount: BigNumber, showDecimals = 4): string => {
  return (
    numeral(
      amount.times(100).toFormat(showDecimals, BigNumber.ROUND_DOWN)
    ).format("0.00a") + "%"
  );
};

export const formatApyFull = (amount: BigNumber, showDecimals = 4): string => {
  return (
    numeral(
      amount.times(100).toFormat(showDecimals, BigNumber.ROUND_DOWN)
    ).format("0.00") + "%"
  );
};

/**
 * format to readable number, like: 0.00 -> 0, 1.00 -> 1, 1.235 -> 1.23, 1.230 -> 1.23
 * @param input
 */
export function formatReadableNumber({
  input,
  showDecimals = 4,
  showPrecisionDecimals = 2,
}: {
  input: BigNumber | number | string;
  showDecimals?: number;
  showPrecisionDecimals?: number;
}): string {
  const source = new BigNumber(input);
  if (source.isNaN()) {
    return "-";
  }
  let amount = source.dp(showDecimals, BigNumber.ROUND_DOWN);
  if (amount.eq(0) && (source.gt(0) || source.lt(0))) {
    amount = source.sd(
      showPrecisionDecimals ?? showDecimals,
      BigNumber.ROUND_DOWN
    );
  }
  return amount.toFormat();
}

/**
 * format to percentage number
 * @param param0 input number
 */
export function formatPercentageNumber({
  input,
}: {
  input: string | number | null;
}): string {
  if (input === null) {
    return "-";
  }
  return `${formatReadableNumber({
    input: Number(input || 0) * 100,
    showDecimals: 2,
  })}%`;
}

const kilo = 1000;
const million = 1000000;
/**
 * format to short number, like: -0.12 -> 0, 0.0000123->0.000012, 123.234 -> 123.23, 1234.12 -> 1.23K, 1000000.123->1.00M
 * @param n
 */
export function formatShortNumber(n?: BigNumber): string {
  if (!n || n.isNaN()) {
    return "-";
  }
  if (n.lte(0)) {
    return "0";
  } else if (n.lt(1)) {
    return formatReadableNumber({ input: n, showDecimals: 2 });
  } else if (n.lt(kilo)) {
    return formatReadableNumber({ input: n, showDecimals: 2 });
  } else if (n.lt(million)) {
    return `${formatReadableNumber({ input: n.div(kilo), showDecimals: 2 })}K`;
  } else {
    return `${formatReadableNumber({
      input: n.div(million),
      showDecimals: 2,
    })}M`;
  }
}
