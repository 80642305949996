import Button, { ButtonVariant } from "@Components/Button";
import React, { useCallback, useEffect, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { WalletType, connectToWallet, getCurrentAccount } from "dodo-wallet";
import { useDispatch, useSelector } from "react-redux";
import Detail from "../detail";
import { Dropdown, Menu } from "antd";
import Main from "./main";
import { truncatePoolAddress } from "../../../utils";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "../actions";
import { DContent, DHeader, DLayout, LanguageMenu, Spanner } from "./style";
import { DownOutlined } from "@ant-design/icons";
import { logFearPageView } from "../../../lib/mixpanel";

const Languages = {
  enUS: "English",
};

export default function Fear() {
  const account = useSelector(getCurrentAccount);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    const lang = i18n.language.replace("-", "");
    if (lang !== "enUS") {
      dispatch({ type: "SET_LANGUAGE", payload: { language: "enUS" } });
    }
  }, [i18n.language]);

  return (
    <DLayout>
      <DHeader>
        <div className="img" />
        <Spanner />

        <nav>
          {account ? (
            <Button variant={ButtonVariant.Yellow}>
              {truncatePoolAddress(account)}
            </Button>
          ) : (
            <Button
              onClick={() => {
                connectToWallet(WalletType.MetaMask);
              }}
              variant={ButtonVariant.Yellow}
            >
              {t("common.connect-wallet")}
            </Button>
          )}
        </nav>
      </DHeader>
      <DContent>
        <Switch>
          <Route exact path="/mystery/fear" component={Main} />
          <Route exact path="/mystery/fear/:tokenId">
            <Detail type={MysteryBoxType.Fear} />
          </Route>
        </Switch>
      </DContent>
    </DLayout>
  );
}
