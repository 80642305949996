import React from "react";

const CountdownImpl: React.FunctionComponent<{ sec: number }> = ({
  sec,
}: {
  sec: number;
}) => {
  if (sec < 0) {
    return <>--</>;
  }
  sec = Math.floor(sec / 1000);
  const s = sec % 60;
  const m = Math.floor(sec / 60) % 60;
  const h = Math.floor(sec / 3600) % 24;
  const d = Math.floor(sec / 86400);

  const segs = [
    [s, "s"],
    [m, "m"],
    [h, "h"],
  ];
  if (d > 0) segs.push([d, "d"]);

  const repr = segs
    .map(([num, unit]) => num.toString().padStart(2, "0") + unit)
    .reverse()
    .join(" : ");
  return <>{repr}</>;
};

export default React.memo(CountdownImpl);
