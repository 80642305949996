import styled from "styled-components";

export const Container = styled.nav`
  max-width: 1440px;
  height: 66px;
  margin: 0px auto;
  padding: 0 32px;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    height: 64px;
    padding: 0 16px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const Right = styled.div``;
