import { getCurrentChainId } from "dodo-wallet";
import React from "react";
import styled from "styled-components";
import { ReactComponent as LinkIcon } from "../images/icons/arrow-up-right.svg";
import { ReactComponent as CloseImg } from "../images/tx-notif-close.svg";
import { ReactComponent as FailedImg } from "../images/tx-notif-failed.svg";
import { ReactComponent as SuccessImg } from "../images/tx-notif-success.svg";
import { Notif, NotifVariant } from "./index";

type NotifProps = {
  notif: Notif;
  onClose: () => void;
};

const NotifWrapper = styled.div<{ success: boolean; temporary: boolean }>`
  margin-bottom: 10px;
  padding: ${({ temporary }) => (temporary ? "16px" : "10px")} 20px;
  display: flex;
  align-items: center;
  ${
    /* --prim-color: ${({ success, theme }) => (success ? theme.palette.green : theme.palette.red)}; */ null
  }
  --prim-color: ${({ success }) => (success ? "green" : "red")};
  --border-begin: ${({ success }) => (success ? "#00fad9" : "#FF9EBA")};
  --border-end: ${({ success }) => (success ? "#ffe804" : "#FF5072")};
  border-radius: 20px;

  height: ${({ temporary }) => (temporary ? "auto" : "90px")};

  > * {
    z-index: 1;
  }

  position: relative;
  background: linear-gradient(var(--border-begin) 0%, var(--border-end) 100%);
  box-shadow: #1a1a1b 0 4px 15px;
`;

const NotifBg = styled.div`
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  ${/*background: ${({ theme }) => theme.palette.popoverBackground};*/ null}
  background: white;
  border-radius: 19px;
  z-index: 0;
`;

const NotifMain = styled.div`
  flex: 1;
  padding: 0 20px;
  font-size: 16px;
`;

const NotifTitle = styled.div`
  color: var(--prim-color);
  margin-bottom: 2px;
`;

const NotifLink = styled.div`
  ${/*color: ${({ theme }) => theme.palette.lightText}; */ null}
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 6px;
    ${/* fill: ${({ theme }) => theme.palette.lightText}; */ null}
    fill: black;

    g {
      ${/* fill: ${({ theme }) => theme.palette.lightText}; */ null}
      fill: black;
    }

    path {
      ${/* fill: ${({ theme }) => theme.palette.lightText}; */ null}
      fill: black;
    }
  }
`;

const CloseWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${/* background: ${({ theme }) => theme.palette.cardBackground}; */ null}
  background: white;

  &:hover {
    path {
      stroke: #fff;
    }
  }
`;

const Entry = React.memo(function EntryImpl({
  notif,
  onClose,
}: NotifProps): React.ReactElement {
  const Icon = notif.variant === NotifVariant.Success ? SuccessImg : FailedImg;

  return (
    <NotifWrapper
      success={notif.variant === NotifVariant.Success}
      temporary={notif.temporary ?? false}
    >
      <NotifBg />
      <Icon />
      <NotifMain>
        <NotifTitle>{notif.title}</NotifTitle>
        {notif.link && (
          <NotifLink onClick={notif.link.onClick}>
            {notif.link.text}
            <LinkIcon />
          </NotifLink>
        )}
      </NotifMain>
      {!notif.temporary && (
        <CloseWrapper
          onClick={onClose}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseImg />
        </CloseWrapper>
      )}
    </NotifWrapper>
  );
});

export default Entry;
