import React from "react";
import { BuyCard } from "../styled";
import Form from "../Form";
import BoxInfo from "../BoxInfo";
import Mine from "../Mine";
import {
  MysteryBoxType,
  useMysteryBoxContractInfo,
  useNftList,
  useTicketsCount,
} from "../actions";
import IntroCard from "../IntroCard";

export default function Main() {
  const {
    currentPrice,
    currentSellingTickets,
    redeemAllowed,
    refetch,
  } = useMysteryBoxContractInfo(MysteryBoxType.YuiHatano);

  const { count: ticketsCount, refetch: refetchTicketsCount } = useTicketsCount(
    MysteryBoxType.YuiHatano
  );

  const {
    nftList,
    refetch: refetchNftList,
    latestNft,
    resetLatestNft,
    loading,
  } = useNftList(MysteryBoxType.YuiHatano);
  return (
    <>
      <BuyCard>
        <BoxInfo
          type={MysteryBoxType.YuiHatano}
          currentPrice={currentPrice}
          currentSellingTickets={currentSellingTickets}
        />
        <Form
          type={MysteryBoxType.YuiHatano}
          refetch={refetch}
          refetchTicketsCount={refetchTicketsCount}
          currentPrice={currentPrice}
          currentSellingTickets={currentSellingTickets}
        />
      </BuyCard>
      <IntroCard type={MysteryBoxType.YuiHatano} />
      <Mine
        type={MysteryBoxType.YuiHatano}
        nftLoading={loading}
        redeemAllowed={redeemAllowed}
        resetLatestNft={resetLatestNft}
        latestNft={latestNft}
        nftList={nftList}
        refetchNftList={refetchNftList}
        ticketsCount={ticketsCount}
        refetchTicketsCount={refetchTicketsCount}
      />
    </>
  );
}
