import {
  Center,
  Container,
  HotTitle,
  ImmediatelyBuyButton,
  Top,
  TopWrapper,
} from "./index.styled";
import { Col, Row } from "antd";

import { Card } from "./Card";
import { Nav } from "./Nav";
import React from "react";
import Tilt from "react-tilt";
import { oldDropsConfig } from "./config";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Banner from "./images/fear-wide-banner.mp4";
import { BannerVideo } from "@Pages/mysteryBox/Fear/style";

/**
 * 旧版盲盒首页
 */
export function OldDrops() {
  const { t } = useTranslation();
  const his = useHistory();
  const hotDrops = oldDropsConfig.find((d) => d.hot);
  return (
    <Container>
      <Nav />
      <TopWrapper>
        <Top>
          <BannerVideo
            src={Banner}
            loop
            autoPlay
            muted
            playsInline
            x5-video-player-fullscreen
            x5-playsinline
            webkit-playsinline
          />
          <HotTitle>
            {t("old-drop.main.hot-drops", { name: t(hotDrops.title) })}
          </HotTitle>
          <ImmediatelyBuyButton
            onClick={() => his.push(`/mystery/${hotDrops.id}`)}
          >
            {t("old-drop.main.immediately-buy")}
          </ImmediatelyBuyButton>
        </Top>
      </TopWrapper>

      <Center>
        <Row gutter={32}>
          {oldDropsConfig.map((d) => {
            return (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={d.id}>
                <Tilt
                  options={{
                    reverse: false, // reverse the tilt direction
                    max: 5, // max tilt rotation (degrees)
                    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
                    scale: 1.05, // 2 = 200%, 1.5 = 150%, etc..
                    speed: 1000, // Speed of the enter/exit transition
                    transition: true, // Set a transition on enter/exit.
                    axis: null, // What axis should be disabled. Can be X or Y.
                    reset: true, // If the tilt effect has to be reset on exit.
                    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
                  }}
                >
                  <Card
                    id={d.id}
                    title={d.title}
                    backgroundImage={d.background}
                    logoImage={d.logoImage}
                    officialName={d.officialName}
                    startDate={d.startDate}
                    endDate={d.endDate}
                  />
                </Tilt>
              </Col>
            );
          })}
        </Row>
      </Center>
    </Container>
  );
}
