import Button, { ButtonVariant } from "@Components/Button";
import { normalizeUri } from "@Utils/net";
import React, { useMemo, useState } from "react";
import { MysteryBoxType, useNftData } from "./actions";
import { Backdrop, CloseButton, TextFont } from "./styled";
import { useTranslation } from "react-i18next";
import Waiting, { WaitingAction } from "./Waiting";
import Close from "../../assets/close.png";
import styled, { keyframes } from "styled-components";
import ImageCover from "../../assets/image-cover.png";
import BoxCover from "../../assets/box-cover.png";
import BoxCoverKAKA from "../../assets/box-cover-kaka.png";
import { generateProxyUrl } from "../../lib/imgProxy";
import OpenedFearVideo from "../../assets/pandoras-box-opened-hd.mp4";

interface Props {
  show: boolean;
  onClose: () => void;
  latestNft?: string;
  type: MysteryBoxType;
}

const collapse = keyframes`
  0%, 50%  {
    top: 0;
    opacity: 1;
  }
  
  100% {
    top: -100%;
    opacity: 0;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 392px;
  height: 500px;
  display: flex;
  align-items: center;
  img {
    position: absolute;
  }
`;

const CoreImg = styled.img`
  width: 392px;
`;

const CoverImg = styled.img`
  width: 392px;
  animation: ${collapse} 4s linear;
  opacity: 0;
`;

export default function OpenSuccess({ show, onClose, latestNft, type }: Props) {
  const data = useNftData(latestNft);
  const { t } = useTranslation();
  const imgUrl = useMemo(() => {
    if (!data) return "";
    return generateProxyUrl({
      url: normalizeUri(data.image),
      width: "784",
      height: "784",
    });
  }, [data]);
  const [showDefault, setShowDefault] = useState<boolean>(true);
  const [displayVideo, setDisplayVideo] = useState<boolean>(
    type === MysteryBoxType.Fear
  );
  const variant = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return ButtonVariant.Yellow;
    else if (type === MysteryBoxType.Fear) return ButtonVariant.Default;
    else return ButtonVariant.Purple;
  }, [type]);
  const image = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return BoxCoverKAKA;
    else if (type === MysteryBoxType.Fear) return null;
    else return BoxCover;
  }, [type]);
  const coverImage = useMemo(() => {
    if (!imgUrl || !show) return null;
    if (type === MysteryBoxType.KAKA) return null;
    else if (type === MysteryBoxType.Fear) {
      if (showDefault && !displayVideo) return null;
      return (
        <video
          width="392"
          src={OpenedFearVideo}
          autoPlay
          playsInline
          x5-video-player-fullscreen
          x5-playsinline
          webkit-playsinline
          onEnded={() => setDisplayVideo(false)}
        ></video>
      );
    } else
      return (
        <CoverImg
          src={ImageCover}
          style={{ display: showDefault ? "none" : "block" }}
        />
      );
  }, [type, imgUrl, show, displayVideo, showDefault]);
  return (
    <Backdrop show={show}>
      <CloseButton onClick={onClose}>
        <img width="40" src={Close} />
      </CloseButton>
      <div style={{ position: "relative", zIndex: 0, textAlign: "center" }}>
        {imgUrl ? (
          <>
            <ImgWrapper>
              <img
                width="392"
                src={image}
                style={{ opacity: showDefault && !displayVideo ? "1" : "0" }}
              />

              <CoreImg
                src={imgUrl}
                style={{
                  opacity: showDefault && !displayVideo ? "0" : "1",
                  transition: "opacity 2s",
                }}
                onLoad={() => setShowDefault(false)}
              />
              {coverImage}
            </ImgWrapper>
            <TextFont
              style={{
                color: "#fff",
                marginTop: 48,
                marginBottom: 42,
                fontSize: 16,
                justifyContent: "center",
              }}
            >
              {t("mystery-box.open-success.tip")}
            </TextFont>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={onClose}
                style={{ width: 120 }}
                variant={variant}
              >
                {t("mystery-box.open-success.continue-button")}
              </Button>
              <a
                href="https://www.treasureland.market"
                target="_blank"
                rel="noreferral noopener"
              >
                <Button style={{ width: 120, marginLeft: 20 }}>
                  {t("mystery-box.open-success.redirect-button")}
                </Button>
              </a>
            </div>
          </>
        ) : (
          <Waiting
            show={show}
            action={WaitingAction.Open}
            type={type}
            text={t("mystery-box.open-success.loading")}
          />
        )}
      </div>
    </Backdrop>
  );
}
