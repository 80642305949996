import React, { useEffect, useState, useMemo, useContext } from "react";
import styled from "styled-components";
import { OrderedMap } from "immutable";
import { v4 as uuidv4 } from "uuid";
import Entry from "./entry";

export enum NotifVariant {
  Success = "Success",
  Failure = "Failure",
}

export type Notif = {
  variant: NotifVariant;
  title: string;
  link?: {
    text: string;
    onClick: () => void;
  };
  temporary?: boolean;
};

export type NotifCtx = {
  show: (notif: Notif, timeout?: number) => void;
};

const NotifContext = React.createContext<NotifCtx>({
  show: () => {
    /* Nothing */
  },
});

export const Area = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 360px;
  /* react-bootstrap modal z-index=1060 */
  /* react-bootstrap tooltip z-index=1070 */
  /* Dialog z-index=1065 */
  z-index: 1071;

  @media (max-width: 767px) {
    width: auto;
    left: 20px;
  }
`;

export const WithNotifArea = React.memo(
  ({ children }: { children: React.ReactNode }): React.ReactElement => {
    const [notifs, setNotifs] = useState<OrderedMap<string, Notif>>(
      OrderedMap()
    );
    const flattened = useMemo(() => Array.from(notifs), [notifs]);

    const ctxVal = useMemo(
      () => ({
        show: (notif: Notif, timeout?: number) => {
          const id = uuidv4();
          setNotifs((n) => n.set(id, notif));

          if (timeout !== undefined) {
            setTimeout(() => {
              setNotifs((n) => n.remove(id));
            }, timeout);
          }
        },
      }),
      []
    );

    return (
      <NotifContext.Provider value={ctxVal}>
        {children}
        <Area>
          {flattened.reverse().map(([uuid, val]) => (
            <Entry
              key={uuid}
              notif={val}
              onClose={() => {
                setNotifs((notifs) => notifs.remove(uuid));
              }}
            />
          ))}
        </Area>
      </NotifContext.Provider>
    );
  }
);

WithNotifArea.displayName = "WithNotifArea";

export function useNotifs(): NotifCtx {
  return useContext(NotifContext);
}
