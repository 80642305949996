import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { initReactI18next } from "react-i18next";
import "moment/locale/zh-cn";
import "moment/locale/ja";
import moment from "moment";
import enUS from "./locales/en-US.json";
import zhCN from "./locales/zh-CN.json";
import jaJP from "./locales/ja-JP.json";

const resources = {
  en: {
    translation: enUS,
  },
  cn: {
    translation: zhCN,
  },
  zh: {
    translation: zhCN,
  },
  ja: {
    translation: jaJP,
  },
  enUS: {
    translation: enUS,
  },
  "en-US": {
    translation: enUS,
  },
  zhCN: {
    translation: zhCN,
  },
  "zh-CN": {
    translation: zhCN,
  },
  jaJP: {
    translation: jaJP,
  },
  "ja-JP": {
    translation: jaJP,
  },
};

const getMomentLocale = (lng) => {
  switch (lng) {
    case "zhCN":
    case "zh-CN":
      return "zh-cn";
    case "enUS":
    case "en-US":
      return "en";
    case "ja-JP":
    case "jaJP":
      return "ja";
    default:
      return lng;
  }
};

const setLocale = (lng) => {
  moment.locale(getMomentLocale(lng));
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init(
    {
      resources,
      fallbackLng: "enUS",
      // debug: process.env.NODE_ENV === 'development',
      keySeparator: false,
      load: "languageOnly",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      returnObjects: true,
    },
    () => {
      setLocale(i18n.language);
    }
  );

i18n.on("languageChanged", function (lng) {
  setLocale(lng);
});

export default i18n;

export const supportLanguages = ["enUS", "zhCN", "jaJP"];

export const currentLanguage = (): string => {
  let lang = i18n.language;
  lang = lang.replace("-", "");
  const supportLangs = supportLanguages.filter((l) => {
    return l.indexOf(lang) > -1;
  });
  let supportLang = "enUS";
  if (supportLangs.length >= 1) {
    supportLang = supportLangs[0];
  }
  return supportLang;
};
