import { useSelector } from "react-redux";
import { DefaultTheme } from "styled-components";
import { IPalette } from "../../styled";

type RightTheme = {
  primaryColor: string;
  backgroundColor: string;
  posterUrl: string;
  settingButton: string;
};

const BasicTheme: DefaultTheme = {
  spacing: {
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "28px",
    8: "32px",

    underlineDistance: 2,
  },
  font: {
    display2: "36px", // Used in price chart now

    emphasize1: "26px",
    emphasize2: "20px",
    title1: "18px",
    title2: "16px",
    normal: "14px",
    intro: "12px",
  },
  palette: {
    divider: "#999999",
    red: "#FF5072",
    green: "#56F6DA",
    mainText: "#2E2E2E",
    mainHoverText: "#2C73DB",
    lightText: "#999999",
    lightHoverText: "#2E2E2E",
    disabledText: "#cccccc",
    chartEmpty: "#BABABA",

    buttonBackground: "#EEEEEE",
    buttonDisabledBackground: "#FAFAFA",
    buttonHoverBackground: "#E3E3E3",
    buttonText: "#2E2E2E",
    buttonHoverText: "#2E2E2E",
    buttonDisabledText: "#2E2E2E",

    pageBackground: "#f6f6f6",
    cardBackground: "white",
    popoverBackground: "white",

    highlightBackground: "white",

    inputBorderError: "#FF5072",
    inputBorderFocused: "#BFBFBF",
    inputBorder: "#EEEEEE",
    inputColor: "black",
    inputBackground: "white",

    highlightText: "black",

    accountPageActBorder: "#EEEEEE",
    accountPageActBorderHover: "#C7C7C7",

    skeletonBg: "#EEEEEE",
    skeletonFg: "white",

    rule: "#666666",
    tokenPickHover: "rgba(127,127,127,0.1)",
  },
  breakpoints: {
    mobile: "767px",
  },
};

const DarkPalette: Partial<IPalette> = {
  divider: "#404040",
  pageBackground: "#1A1A1B",
  cardBackground: "#1A1A1B",
  popoverBackground: "#2A2A2D",

  lightText: "#85858D",
  mainText: "#FFFFFF",
  mainHoverText: "#FFE804",

  chartEmpty: "#333",

  buttonBackground: "#262729",
  buttonDisabledBackground: "#333333",
  buttonHoverBackground: "#403E25",
  buttonText: "#FFFFFF",
  buttonHoverText: "#FFE804",
  buttonDisabledText: "#1A1A1B",

  lightHoverText: "#FFFFFF",

  highlightBackground: "#343638",

  inputBorderFocused: "transparent",
  inputBorder: "transparent",
  inputColor: "white",
  inputBackground: "#373739",
  inputPlaceholder: "#505056",

  highlightText: "#FFE804",

  accountPageActBorder: "#2E2E2E",
  accountPageActBorderHover: "#FFE804",

  skeletonBg: "#373739",
  skeletonFg: "#222225",

  tokenPickHover: "#373739",
};

export type ThemeType = "light" | "dark";
export const getTheme = (
  type: ThemeType,
  right: RightTheme | null = null
): DefaultTheme => {
  let theme: DefaultTheme = BasicTheme;
  switch (type) {
    case "light":
      theme = {
        spacing: {
          ...BasicTheme.spacing,
        },
        font: {
          ...BasicTheme.font,
        },
        palette: {
          ...BasicTheme.palette,
        },
        breakpoints: {
          ...BasicTheme.breakpoints,
        },
      };
      break;
    case "dark":
      Object.assign(theme.palette, DarkPalette);

    default:
      break;
  }
  if (right) return { ...theme, right };
  else return theme;
};

export const getCurrentTheme = (): DefaultTheme => {
  // const themeConfig = useSelector<RootState, ThemeType>((state) => state.settings.get('theme'));
  const themeConfig = "light";
  return getTheme(themeConfig);
};
