import styled from "styled-components";

export const Container = styled.div`
  margin-top: 32px;
  min-height: 400px;
  background: linear-gradient(
    180deg,
    rgba(26, 26, 27, 0) 0%,
    rgba(26, 26, 27, 0.8) 100%
  );
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 0px 40px 32px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    margin-top: 16px;
    min-height: 200px;
    padding: 0px 14px 17px;
  }
`;

export const Title = styled.div`
  margin-top: auto;

  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  line-height: 55px;

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const OfficialSection = styled.div`
  margin-top: 9px;
  display: flex;
  align-items: center;
  .logo {
    background: #ffd43d;
    border-radius: 2px;
    font-size: 12px;
    width: 100px;
    height: 40px;
    font-weight: bold;
    color: #000000;
    line-height: 18px;
    padding: 2px 8px 3px 9px;
  }

  .name {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 17px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    .logo {
      width: 51px;
      height: 22px;
    }

    .name {
      margin-left: 10px;
    }
  }
`;

export const StartDateSection = styled.div`
  margin-top: 19px;

  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    margin-top: 12px;
  }
`;

export const DateTip = styled.div`
  display: flex;
  align-items: center;
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  font-size: 20px;
  font-weight: bold;
  color: #242428;
  line-height: 30px;
`;

export const TimeUnit = styled.div`
  margin: 0px 8px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  line-height: 18px;
`;
