import React, { useEffect, useMemo, useRef } from "react";
import styled, { keyframes } from "styled-components";
import BoxCover from "../../assets/box-cover.png";
import { MysteryBoxType } from "./actions";
import BoxCoverKAKA from "../../assets/box-cover-kaka.png";
import BoxCoverFearVideo from "../../assets/blindbox.mp4";
import OpeningFearVideo from "../../assets/opening-pandoras-box-loop-hd.mp4";

const rotate = keyframes`
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;

const scale = keyframes`
  0% {
    width: 40px;
  }
  100% {
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  animation: ${rotate} 3s linear infinite 2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 272px;
  height: 400px;
  img {
    animation: ${scale} 1s;
    max-width: 100%;
  }
`;

export enum WaitingAction {
  Buy,
  Open,
}

interface Props {
  text: string;
  type: MysteryBoxType;
  action: WaitingAction;
  show?: boolean;
}

export default function Waiting({ text, type, action, show }: Props) {
  const image = useMemo(() => {
    if (!show) return null;
    if (type === MysteryBoxType.KAKA) {
      return (
        <ImgContainer>
          <img src={BoxCoverKAKA} />
        </ImgContainer>
      );
    } else if (type === MysteryBoxType.Fear) {
      if (action === WaitingAction.Buy) {
        return (
          <video
            width="272"
            src={BoxCoverFearVideo}
            loop
            autoPlay
            muted
            playsInline
            x5-video-player-fullscreen
            x5-playsinline
            webkit-playsinline
          ></video>
        );
      } else {
        return (
          <video
            width="272"
            src={OpeningFearVideo}
            loop
            autoPlay
            playsInline
            x5-video-player-fullscreen
            x5-playsinline
            webkit-playsinline
          ></video>
        );
      }
    } else
      return (
        <ImgContainer>
          <img src={BoxCover} />
        </ImgContainer>
      );
  }, [type, show]);
  return (
    <>
      {image}
      <div style={{ marginTop: 48, marginBottom: 42, fontSize: 16 }}>
        {text}
      </div>
    </>
  );
}
