import { BigNumber } from "bignumber.js";
import { Token } from "dodo-wallet";
import Web3 from "web3";

export const isEthToken = (token: Token): boolean => {
  return token.get("symbol") === "ETH" || token.get("symbol") === "WETH";
};

export const getTokenSymbolDisplay = (baseToken: Token): string => {
  let originSymbol = baseToken.get("symbol");

  /*
   * Now we are displaying WETH as is
  if (originSymbol.indexOf('WETH') > -1) {
    return 'ETH';
  }
  */
  if (originSymbol.indexOf("DLP_") > -1) {
    originSymbol = originSymbol.replace("DLP_", "");
  }

  return originSymbol;
};

export const getTokenAddress = (target: Token): string => {
  return target.get("address").toLowerCase();
};

export const isSameAddress = (
  tokenAddress1: string,
  tokenAddress2: string
): boolean => {
  if (tokenAddress1.length === 0 || tokenAddress2.length === 0) {
    return false;
  }
  if (tokenAddress2.length === tokenAddress1.length) {
    return tokenAddress1.toLowerCase() === tokenAddress2.toLowerCase();
  } else {
    const trimAddress1 = tokenAddress1
      .substring(2, tokenAddress1.length)
      .toLowerCase();
    const trimAddress2 = tokenAddress2
      .substring(2, tokenAddress2.length)
      .toLowerCase();
    if (trimAddress1.length > trimAddress2.length) {
      return trimAddress1.endsWith(trimAddress2);
    } else {
      return trimAddress2.endsWith(trimAddress1);
    }
  }
};

export const capitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const sleep = (t: number): Promise<void> =>
  new Promise((r) => setTimeout(r, t));

// TODO(meow): maybe let's use lodash/throttle?
export const loadingMap = new Map();
export const throttle = <Args extends never[]>(
  key: string,
  func: (..._args: Args) => void,
  timeout: number
) => {
  return (...args: Args): void => {
    if (loadingMap.get(key)) {
      return;
    }
    loadingMap.set(key, true);
    setTimeout(() => {
      loadingMap.set(key, false);
      func(...args);
    }, timeout);
    // func(...args);
  };
};

export const removeServerStateElement = (): void => {
  if (!document) return;

  const el = document.getElementById("server-app-state");
  if (el) el.remove();
};

export const removeEnvElement = (): void => {
  if (!document) return;

  const el = document.getElementById("window-env");
  if (el) el.remove();
};

export const fixedInputStringToFormattedNumber = (
  inputValue: string,
  inputTokenShowDecimals: number
): string | null => {
  let inputString = inputValue;
  const regexFixedStrings = inputString.match("[0-9。.]+");
  if (regexFixedStrings && regexFixedStrings.length > 0) {
    inputString = regexFixedStrings[0];
  } else {
    inputString = "";
  }

  inputString = inputString.replace("。", ".");
  let decimalsCount = 0;
  if (inputString) {
    const inputSplits = inputString.split(".");
    const fixedInputSplits =
      inputSplits[0].length === 0 ? ["0"] : [inputSplits[0]];
    if (inputSplits.length > 1) {
      fixedInputSplits[1] = inputSplits.slice(1, inputSplits.length).join("");
    } else {
      if (fixedInputSplits[0].length > 1 && fixedInputSplits[0][0] === "0") {
        fixedInputSplits[0] = fixedInputSplits[0].slice(
          1,
          fixedInputSplits[0].length
        );
      }
    }
    if (fixedInputSplits.length > 1) {
      decimalsCount = fixedInputSplits[1].length;
    }
    inputString = fixedInputSplits.join(".");
  }

  if (inputTokenShowDecimals >= 0 && decimalsCount > inputTokenShowDecimals) {
    return null;
  } else {
    return inputString;
  }
};

export function formatTimestamp(timestamp?: number): string {
  if (!timestamp) {
    return "-";
  }
  const dateTime = new Date(Number(timestamp) * 1000);
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1;
  const date = dateTime.getDate();

  return `${year}/${month}/${date}`;
}

export function fromNow(timestamp?: number): string {
  if (!timestamp) {
    return "";
  }
  const diff = Date.now() - timestamp;
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = month * 12;

  if (diff < 5 * minute) {
    return "Just now";
  }
  if (diff < hour) {
    return `${(diff / minute).toFixed(0)} mins`;
  }
  if (diff < day) {
    return `${(diff / hour).toFixed(0)} hrs`;
  }
  if (diff < month) {
    return `${(diff / day).toFixed(0)} days`;
  }
  if (diff < year) {
    return `${(diff / month).toFixed(0)} months`;
  }
  return `${(diff / year).toFixed(0)} years`;
}

export function reformatNumber(
  raw: string,
  precision: number,
  append = false
): string {
  // FIXME: should improve this. with toFixed method.
  if (raw.indexOf("e") > 0) {
    return new BigNumber(raw).div(`1e${precision}`).toString(10);
  }
  // support negative number
  if (!raw.match(/^\d+(.\d+)?$/) && raw.indexOf("-") === -1) {
    return raw === "NaN" ? "Loading" : raw;
  }
  const [i, frac] = raw.split(".");
  if (frac === undefined) {
    if (append) return i + "." + "0".repeat(precision);
    return i;
  }

  const front = frac.substr(0, precision);
  return `${i}.${front}`;
}

/**
 * format seconds to 59:59
 * @param sec second
 */
export function formatTimeToMinSec(sec: number): string {
  if (sec < 0) return "00:00";

  const minStr = Math.floor(sec / 60)
    .toString()
    .padStart(2, "0");
  const secStr = Math.floor(sec % 60)
    .toString()
    .padStart(2, "0");
  return `${minStr}:${secStr}`;
}

/**
 * If null is returned, then a timeout is reached
 * time is measured in ms
 *
 * TODO(meow): time errors as well?
 */
export function timePromise<T extends unknown>(
  promise: Promise<T>,
  timeout?: number
): Promise<[number, T] | null> {
  const start = performance.now();
  const mapped = promise.then(
    (t) => [performance.now() - start, t] as [number, T]
  );
  if (timeout === null) return mapped;
  return Promise.race([
    mapped,
    new Promise((resolve) => setTimeout(() => resolve(null), timeout)),
  ]) as Promise<[number, T] | null>;
}

export function wrapUrl(
  url: string | null | undefined
): string | null | undefined {
  if (!url || url.length === 0) return null;
  return `url(${url})`;
}

/**
 * truncate pool address: 0xeBa959390016dd81419A189e5ef6F3B6720e5A90 => 0xeBa9...5A90
 * @param address pool address
 */
export function truncatePoolAddress(address: string): string {
  if (address.length <= 10) {
    return address;
  }
  return `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
}

export function formatUnknownTokenSymbol(
  token?: { symbol: string; name: string } | null
) {
  if (!token) {
    return "";
  }
  return token.symbol === "unknown" ? token.name : token.symbol;
}

export const secondsToDays = (seconds: number) => {
  return Math.ceil(seconds / 1000 / 60 / 60 / 24);
};

export const secondsToHour = (seconds: number) => {
  return Math.ceil(seconds / 1000 / 60 / 60);
};

export const uniqueObjectArrayByKey = (array: Array<any>, key: string) => {
  const flag = {};
  const unique: Array<any> = [];
  array.forEach((obj) => {
    const val = obj[key];
    if (!flag[val]) {
      unique.push(obj);
      flag[val] = true;
    }
  });
  return unique;
};

export const parseTextTemplateWithVariables = (
  template: string,
  variables: { [key: string]: string | number | undefined }
) => {
  Object.keys(variables).forEach((key) => {
    const reg = new RegExp(`{{${key}}}`, "g");
    template = template.replace(reg, String(variables[key] ?? "--"));
  });
  return template;
};

/**
 * 42 chars and start with '0x'
 * 0x514426865aa03deaf9684a28eaef3e0d726db48c
 * @param input
 */
export function isLikePoolAddress(input: string): boolean {
  if (!input) {
    return false;
  }
  if (input.startsWith("0x") && input.length >= 42) {
    return true;
  }
  return false;
}

export const getValidDecimals = (num: BigNumber) => {
  const numStr = num.toPrecision(2);
  const [, decimals] = numStr.split(".");
  if (decimals === undefined) {
    return 0;
  }
  return decimals.length;
};

export const OneDayTime = 60 * 60 * 24 * 1000;

export const toWei = (
  amount: BigNumber | string | number,
  decimals: number
) => {
  return new BigNumber(amount).times(new BigNumber(10).pow(decimals)).dp(0);
};

export const fixEthBalance = (balance: BigNumber): BigNumber => {
  const realETH = balance.div(new BigNumber(10).pow(18));
  // return realETH.lt(0.2) ? new BigNumber(0) : realETH.minus(0.2);
  // https://app.asana.com/0/1198998505857296/1199687490690589/f
  return realETH;
};

export const renderSubtitle = (
  subTitle: string | undefined,
  translation: (key: string, params: Record<string, unknown>) => string
) => {
  if (!subTitle) return subTitle;

  let data: { key: string; [propsName: string]: any };

  try {
    data = JSON.parse(subTitle);
  } catch (error) {
    return subTitle;
  }

  const { key, ...params } = data;

  if (key && typeof key === "string") {
    return translation(key, params);
  } else {
    throw new TypeError("key is not a string");
  }
};

export const ETH_ADDR_OLD = "0x000000000000000000000000000000000000000e";
export const ETH_ADDR = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export const ETH_ADDR_CASE_SENSITIVE =
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const ETH_ADDRS = [ETH_ADDR_OLD, ETH_ADDR, ETH_ADDR_CASE_SENSITIVE];

export const formatAddress = (address: string): string => {
  try {
    return Web3.utils.toChecksumAddress(address);
  } catch (error) {
    // console.log(error);
    return "";
  }
};

const composeTokenUrl = (address: string, isBSC = false): string => {
  const chainName = isBSC ? "bsc" : "ethereum";
  return `https://dodoex.coding.net/p/assets/d/assets/git/raw/master/blockchains/${chainName}/assets/${address}/logo.png`;
};

export const getTokenLogoUrl = (
  token: Token,
  symbol: string,
  isEth = false,
  isBSC = false
): string => {
  let address = token.get("address");
  const url = token.get("logoUrl");
  if (url) {
    return url;
  }
  if (isEth) {
    if (isBSC) {
      return "https://dodoex.coding.net/p/assets/d/assets/git/raw/master/blockchains/binance/info/logo.png";
    } else {
      return "https://dodoex.coding.net/p/assets/d/assets/git/raw/master/blockchains/ethereum/info/logo.png";
    }
  }
  address = formatAddress(address);
  return composeTokenUrl(address, isBSC);
};
