import { ButtonVariant } from "@Components/Button";
import React, { useMemo, useState } from "react";
import { MineWrapper, Flex, OutlineButton } from "./styled";
import Thumbnail from "./Thumbnail";
import { OrderedMap } from "immutable";
import OpenSuccess from "./OpenSuccess";
import { useTranslation } from "react-i18next";
import NFTList from "./NFTList";
import { MysteryBoxType } from "./actions";

enum Tab {
  Box,
  NFT,
}

interface Props {
  ticketsCount: number;
  nftList: OrderedMap<string, string>;
  refetchNftList: () => void;
  refetchTicketsCount: () => void;
  latestNft?: string;
  resetLatestNft: () => void;
  redeemAllowed: boolean;
  nftLoading: boolean;
  type: MysteryBoxType;
}

export default function Mine({
  ticketsCount,
  refetchTicketsCount,
  nftList,
  refetchNftList,
  latestNft,
  resetLatestNft,
  redeemAllowed,
  nftLoading,
  type,
}: Props) {
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Box);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { t } = useTranslation();
  const ListNode = useMemo(() => {
    if (currentTab === Tab.Box) {
      return (
        <Thumbnail
          type={type}
          redeemAllowed={redeemAllowed}
          showSuccess={() => setShowSuccess(true)}
          ticketsCount={ticketsCount}
          refetchTicketsCount={refetchTicketsCount}
          refetchNftList={refetchNftList}
          resetLatestNft={resetLatestNft}
        />
      );
    } else if (currentTab === Tab.NFT) {
      return <NFTList type={type} nftList={nftList} nftLoading={nftLoading} />;
    }
  }, [currentTab, ticketsCount, refetchTicketsCount, nftList, refetchNftList]);

  const [activeVariant, defaultVariant] = useMemo(() => {
    if (type === MysteryBoxType.KAKA)
      return [ButtonVariant.Blue, ButtonVariant.White];
    else if (type === MysteryBoxType.Fear)
      return [ButtonVariant.Default, ButtonVariant.White];
    else return [ButtonVariant.Purple, ButtonVariant.White];
  }, [type]);

  return (
    <MineWrapper type={type}>
      <OpenSuccess
        type={type}
        show={showSuccess}
        latestNft={latestNft}
        onClose={() => {
          setShowSuccess(false);
          resetLatestNft();
        }}
      />
      <Flex
        style={{
          marginBottom: 30,
          justifyContent: "center",
          zIndex: 1,
          position: "relative",
        }}
      >
        <OutlineButton
          _type={type}
          variant={currentTab === Tab.Box ? activeVariant : defaultVariant}
          onClick={() => setCurrentTab(Tab.Box)}
        >
          {t("mystery-box.mine.tab.box")}
        </OutlineButton>
        <OutlineButton
          _type={type}
          variant={currentTab === Tab.NFT ? activeVariant : defaultVariant}
          style={{ marginLeft: 20 }}
          onClick={() => setCurrentTab(Tab.NFT)}
        >
          {t("mystery-box.mine.tab.nft")}
        </OutlineButton>
      </Flex>
      <Flex
        style={{
          flexWrap: "wrap",
          justifyContent: "center",
          zIndex: 1,
          position: "relative",
        }}
      >
        {ListNode}
      </Flex>
    </MineWrapper>
  );
}
