import Cookies from "js-cookie";

const cookies: Record<string, any> = {};

/**
 * @description set cookie
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function (name = "default", value = "", cookieSetting = {}) {
  const currentCookieSetting = {
    expires: 1,
  };
  Object.assign(currentCookieSetting, cookieSetting);
  Cookies.set(`dodoex-app-${name}`, value, currentCookieSetting);
};

/**
 * @description get cookie
 * @param {String} name cookie name
 */
cookies.get = function (name = "default") {
  return Cookies.get(`dodoex-app-${name}`);
};

/**
 * @description get all cookie
 */
cookies.getAll = function () {
  return Cookies.get();
};

/**
 * @description delete cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = "default") {
  return Cookies.remove(`dodoex-app-${name}`);
};

export default cookies;
