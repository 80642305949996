import { DownOutlined } from "@ant-design/icons";
import Button, { ButtonVariant } from "@Components/Button";
import { LanguageMenu } from "@Pages/mysteryBox/YuiHatano/style";
import { Dropdown, Menu } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../../assets/icon.png";
import { Container, Left, Right } from "./Nav.styled";

const Languages = {
  zhCN: "简体中文",
  enUS: "English",
  jaJP: "日本語",
};

export function Nav() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const setLanguage = useCallback((language: string) => {
    dispatch({ type: "SET_LANGUAGE", payload: { language } });
  }, []);

  return (
    <Container>
      <Left>
        <Link to="/">
          <img src={Icon} width="30px" />
        </Link>
      </Left>

      <Right>
        <Dropdown
          overlay={
            <LanguageMenu>
              {Object.keys(Languages).map((lang) => {
                return (
                  <Menu.Item
                    key={lang}
                    onClick={() => setLanguage(lang)}
                    style={{ padding: "8px 24px" }}
                  >
                    {Languages[lang]}
                  </Menu.Item>
                );
              })}
            </LanguageMenu>
          }
        >
          <Button
            variant={ButtonVariant.Yellow}
            style={{ padding: "0 16px", minWidth: 124 }}
          >
            {Languages[i18n.language]}
            <DownOutlined style={{ width: 24, marginLeft: 10 }} />
          </Button>
        </Dropdown>
      </Right>
    </Container>
  );
}
