import React, { useMemo } from "react";
import { OrderedMap } from "immutable";
import NFTCard from "./NFTCard";
import EmptyImage from "../../assets/empty-box.png";
import EmptyImageKAKA from "../../assets/empty-box-kaka.png";
import EmptyImageFear from "../../assets/empty-box-fear.png";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "./actions";

interface Props {
  nftList: OrderedMap<string, string>;
  nftLoading: boolean;
  type: MysteryBoxType;
}

export default function NFTList({ nftList, nftLoading, type }: Props) {
  const { t } = useTranslation();
  const image = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return EmptyImageKAKA;
    else if (type === MysteryBoxType.Fear) return EmptyImageFear;
    else return EmptyImage;
  }, [type]);
  if (nftList.size === 0) {
    return (
      <div>
        <div style={{ marginTop: 37, marginBottom: 37 }}>
          <img width="108" src={image} />
        </div>
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          {nftLoading ? "Loading..." : t("mystery-box.thumbnail.empty")}
        </div>
      </div>
    );
  }
  return (
    <>
      {nftList
        .toArray()
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map(([tokenID, nft]) => (
          <NFTCard type={type} key={nft} nft={nft} tokenID={tokenID} />
        ))}
    </>
  );
}
