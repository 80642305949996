import i18n from "i18next";

import { WalletReducer, WalletState, initWallet } from "dodo-wallet";
import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import settings, { State as SettingsState } from "./settings";

export interface RootState extends WalletState {
  settings: SettingsState;
}

const rootReducer = combineReducers({
  ...WalletReducer,
  settings,
});

const middlewares: any = [thunkMiddleware];

const enhancer = compose(applyMiddleware(...middlewares));

const store = createStore(rootReducer, undefined, enhancer);

export default store;

store.subscribe(() => {
  const state = store.getState();
  if (state.settings.language !== i18n.language)
    i18n.changeLanguage(state.settings.language);
});
