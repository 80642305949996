import { Layout, Menu } from "antd";
import styled from "styled-components";
import Button from "@Components/Button";
import Logo from "../../../assets/logo-fear.png";
import whiteLogo from "../../../assets/white-logo.png";

const { Header, Content } = Layout;

export const DLayout = styled(Layout)`
  background: #151516;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  color: #fff;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`;

export const DHeader = styled(Header)`
  height: 64px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  background: transparent;
  .img {
    height: 42px;
    width: 349px;
    background: url(${Logo});
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
  }
  ${Button} {
    margin-left: 24px;
  }
  @media screen and (max-width: 767px) {
    .img {
      height: 100%;
      width: 165px;
      background: url(${whiteLogo});
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
    }
    ${Button} {
      margin-left: 4px;
    }
  }
`;

export const Spanner = styled.div`
  flex: 1;
`;

export const DContent = styled(Content)`
  color: #fff;
  font-size: 14px;
  flex: 1;
  min-height: 100vh;
  margin-top: 24px;
  margin-bottom: 60px;
`;

export const LanguageMenu = styled(Menu)`
  width: 160px;
`;

export const BannerVideo = styled.video`
  max-width: 100%;
  margin: 0 auto 24px;
  display: block;
  width: 1440px;
`;
