import { normalizeUri } from "@Utils/net";
import React, { useEffect, useMemo, useState } from "react";
import {
  getTypeI18nKey,
  getTypeRoutePath,
  MysteryBoxType,
  useNftData,
} from "./actions";
import {
  ThumbnailImgWrapper,
  ThumbnailWrapper,
  MenuButton,
  ExportButton,
} from "./styled";
import Export from "../../assets/export.png";
import ExportNFT from "./ExportNFT";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BigSizeImage from "./BigSizeImage";
import { generateProxyUrl } from "../../lib/imgProxy";
import TextLink from "@Components/TextLink";

interface Props {
  nft: string;
  tokenID: string;
  type: MysteryBoxType;
}

export default function NFTCard({ nft, tokenID, type }: Props) {
  const [showExport, setShowExport] = useState<boolean>(false);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const data = useNftData(nft);

  const history = useHistory();
  const imgUrl = useMemo(() => {
    if (!data) return "";
    return generateProxyUrl({
      url: normalizeUri(data.image),
      width: "544",
      height: "544",
    });
  }, [data]);
  const rarity = data?.attributes.find((a) => a.trait_type === "rarity")?.value;
  return (
    <>
      <ExportNFT
        show={showExportDialog}
        onClose={() => setShowExportDialog(false)}
        imgUrl={imgUrl}
        tokenID={tokenID}
        type={type}
      />
      <ThumbnailWrapper type={type}>
        <MenuButton onClick={() => setShowExport((show) => !show)}>
          <img src={Export} />
          <ExportButton
            show={showExport}
            onClick={() => setShowExportDialog(true)}
          >
            {t("mystery-box.nft-card.export-nft")}
          </ExportButton>
        </MenuButton>
        <ThumbnailImgWrapper
          style={{ marginTop: 20 }}
          onClick={() =>
            history.push(`/mystery/${getTypeRoutePath(type)}/${tokenID}`)
          }
        >
          {!videoLoaded && <BigSizeImage imgUrl={imgUrl} type={type} />}
          {data?.animation_url && (
            <video
              src={normalizeUri(data?.animation_url, true)}
              loop
              autoPlay
              muted
              playsInline
              x5-video-player-fullscreen
              x5-playsinline
              webkit-playsinline
              style={{
                opacity: videoLoaded ? "1" : "0",
                position: "absolute",
                width: "100%",
                maxHeight: "100%",
              }}
              onPlay={() => setVideoLoaded(true)}
            />
          )}
        </ThumbnailImgWrapper>
        <div
          style={{
            padding: 24,
            zIndex: 0,
            position: "relative",
            width: "100%",
          }}
        >
          <div style={{ marginBottom: 12, display: "flex" }}>
            {data?.name
              ? data?.name
              : t(`mystery-box.nft-card.name${getTypeI18nKey(type)}`)}
            {rarity && <div> · {t(`mystery-box.box-info.${rarity}`)}</div>}
          </div>
          <TextLink
            style={{ marginBottom: 12, fontSize: 12 }}
            to={`/mystery/${getTypeRoutePath(type)}/${tokenID}`}
          >
            TOKEN ID{t("colon")}
            {tokenID}
          </TextLink>
        </div>
      </ThumbnailWrapper>
    </>
  );
}
