import {
  BlueColor,
  BoxInfoContent,
  BoxInfoWrapper,
  PriceWrapper,
  PurpleColor,
  PurpleFont,
  TextFont,
} from "./styled";
import { MysteryBoxType, getTypeI18nKey } from "./actions";
import React, { useMemo } from "react";

import BigNumber from "bignumber.js";
import CoverImage from "../../assets/box-cover.png";
import KAKABoxInfoRankList from "./KAKA/BoxInfoRankList";
import FearBoxInfoRankList from "./Fear/BoxInfoRankList";
import KAKACoverImage from "../../assets/box-cover-kaka.png";
import Tilt from "react-tilt";
import YuiHatanoBoxInfoRankList from "./YuiHatano/BoxInfoRankList";
import { formatReadableNumber } from "@Utils/formatter";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import BoxCoverFearVideo from "../../assets/blindbox.mp4";

const Title = styled.div<{ type?: MysteryBoxType }>`
  font-size: 24px;
  margin-bottom: 12px;
  color: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return BlueColor;
    else if (type === MysteryBoxType.Fear) return "#fff";
    else return PurpleColor;
  }};
  font-weight: bold;
`;

interface Props {
  currentPrice: BigNumber;
  currentSellingTickets: number;
  type: MysteryBoxType;
}

export default function BoxInfo({
  currentPrice,
  currentSellingTickets,
  type,
}: Props) {
  const { t } = useTranslation();
  const RankList = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return KAKABoxInfoRankList;
    else if (type === MysteryBoxType.Fear) return FearBoxInfoRankList;
    else return YuiHatanoBoxInfoRankList;
  }, [type]);
  const image = useMemo(() => {
    if (type === MysteryBoxType.KAKA)
      return (
        <img
          height="326"
          src={KAKACoverImage}
          style={{ margin: "36px 72px" }}
        />
      );
    else if (type === MysteryBoxType.Fear)
      return (
        <video
          style={{ margin: "36px 72px" }}
          width="272"
          src={BoxCoverFearVideo}
          loop
          autoPlay
          muted
          playsInline
          x5-video-player-fullscreen
          x5-playsinline
          webkit-playsinline
        ></video>
      );
    else
      return (
        <img height="326" src={CoverImage} style={{ margin: "36px 72px" }} />
      );
  }, [type]);
  const total = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return 5337;
    else if (type === MysteryBoxType.Fear) return 666;
    else return 3000;
  }, [type]);
  return (
    <>
      <BoxInfoWrapper>
        <Tilt
          options={{
            reverse: false, // reverse the tilt direction
            max: 5, // max tilt rotation (degrees)
            perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
            scale: 1.05, // 2 = 200%, 1.5 = 150%, etc..
            speed: 1000, // Speed of the enter/exit transition
            transition: true, // Set a transition on enter/exit.
            axis: null, // What axis should be disabled. Can be X or Y.
            reset: true, // If the tilt effect has to be reset on exit.
            easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
          }}
        >
          {image}
        </Tilt>
        <BoxInfoContent>
          <Title type={type}>
            {t(`mystery-box.box-info.title${getTypeI18nKey(type)}`)}
          </Title>
          <TextFont type={type} bold style={{ fontSize: 16, marginBottom: 8 }}>
            {t("mystery-box.box-info.current-stage")}
          </TextFont>
          <PriceWrapper type={type}>
            <div>
              {t("mystery-box.box-info.init-price")}
              {t("colon")}
              <PurpleFont
                bold
                style={{ fontSize: 20, marginLeft: 4 }}
                type={type}
              >
                {formatReadableNumber({ input: currentPrice, showDecimals: 2 })}{" "}
                BNB
              </PurpleFont>
            </div>
            <div>
              {t("mystery-box.box-info.current-stock")}
              {t("colon")}
              <PurpleFont
                bold
                style={{ fontSize: 20, marginLeft: 4 }}
                type={type}
              >
                {formatReadableNumber({ input: currentSellingTickets })}
              </PurpleFont>
            </div>
          </PriceWrapper>
          <TextFont type={type} bold style={{ fontSize: 16, marginBottom: 12 }}>
            {t("mystery-box.box-info.total-stock")}
            {t("colon")}
            {total}
          </TextFont>
          <RankList />
        </BoxInfoContent>
      </BoxInfoWrapper>
    </>
  );
}
