import React, { ReactElement } from "react";
import styled from "styled-components";
import Dialog from "./Dialog";

type Params = {
  on: boolean;
  onChoose?: (confirm: boolean) => void;

  title: string;
  hint?: string;
};

const Title = styled.div`
  color: ${({ theme }) => theme.palette.mainText};
  margin-top: 6px; // Header has 24px
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 28px;
  font-weight: 500;

  text-align: center;
`;

const Hint = styled.div`
  line-height: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.lightText};
  text-align: center;

  margin-bottom: 20px;
`;

const Actions = styled.div`
  display: flex;
`;

// TODO: Can we actually merge this with the ordinary buttons?
const Action = styled.button`
  color: ${({ theme }) => theme.palette.mainText};
  margin-right: 8px;
  font-size: 16px;
  font-weight: 500px;

  &:last-child {
    margin-right: 0;
    color: #ff5072;
  }

  background: #373739;
  cursor: pointer;

  border: 0;
  outline: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 46px;
  flex: 1;

  &:hover,
  &:focus {
    outline: 0;
    background: #414143;
  }
`;

export default React.memo(function Confirm({
  on,
  onChoose,
  title,
  hint,
}: Params): ReactElement {
  return (
    <Dialog on={on} slim={true}>
      <Title>{title}</Title>
      <Hint>{hint}</Hint>
      <Actions>
        <Action onClick={onChoose && (() => onChoose(false))}>取消</Action>
        <Action onClick={onChoose && (() => onChoose(true))}>确定</Action>
      </Actions>
    </Dialog>
  );
});
