/**
 * Let's pretend that typescript has ADT
 */

import { BigNumber } from "bignumber.js";
import { SDKToken } from "../types/token";

/**
 * All supported opcodes, as tagged union discriminants
 */
export enum OpCode {
  Approval = "APPROVAL",
  TX = "TX",
}

export type TokenData = SDKToken | string;

export type ApprovalStep = {
  opcode: OpCode.Approval;

  /**
   * The address of the token
   */
  token: TokenData;

  /**
   * The approved contract address
   */
  contract: string;

  /**
   * The amount to set the allowance to, in Wei, in base-10. If omitted, a (very) big constants is used
   * TODO(meow): bigint shows a 87% support rate. Can we use it here?
   */
  amt?: BigNumber;
};

export type TXStep = {
  opcode: OpCode.TX;

  value: number | string;
  to: string;
  data: string;

  // Gas is handled by the tx submodule

  gasLimit?: number;
  gasPrice?: number;
};

export type Step = ApprovalStep | TXStep;
export type Steps = Step[];
