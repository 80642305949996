import { DODO_SDK_DATA } from "@owen05/dodo-sdk-v2/build/src/data/constants";
import { Chain } from "./types/chain";
import { TokenInterface } from "./types/token";

export const PERSISTENT_TX_HISTORY = "dodo:wallet:txhist";

export const chainIdToChainConfig: Record<number, Chain> = {
  // https://chainid.network/chains.json
  // ETH
  // mainnet
  1: {
    name: "Ethereum Mainnet",
    chainId: 1,
    chain: "ETH",
    network: "mainnet",
    networkId: 1,
    configUrl: "https://config.dodoex.io/configs/mainnet.json",
    defaultEndpointUrl: "https://api.taichi.network:10001/rpc/public",
    defaultROEndpointUrl: "https://api.taichi.network:10001/rpc/public",
    defaultTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-v2-beta",
    // defaultTheGraphUrl: 'https://api.thegraph.com/subgraphs/name/dodoex/dodoex-v2',
    defaultTokenTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-token",
    defaultVDODOTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-vdodo",
    multicallAddr: DODO_SDK_DATA.MULTI_CALL,
    chiAddress: "0x0000000000004946c0e9F43F4Dee607b0eF1fA1c",
    fromTokenInit: {
      symbol: "ETH",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      name: "Ether",
      decimals: 18,
      source: "dodo",
    },
    toTokenInit: {
      symbol: "USDC",
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      name: "USD Coin",
      decimals: 6,
      source: "dodo",
    },
    scanUrlDomain: {
      enUS: "etherscan.io",
      zhCN: "cn.etherscan.com",
    },
    etherscanApiUrl: "api.etherscan.io",
    dodoApproveProxyV2: DODO_SDK_DATA.DODO_APPROVE,
    routeV1DataFetch: DODO_SDK_DATA.ROUTE_V1_DATA_FETCH,
    blockCountPerDay: 6700,
    tradeIncentive: true,
    blockTime: 13000,
  },
  // kovan
  42: {
    name: "Ethereum Testnet Kovan",
    chainId: 42,
    chain: "ETH",
    network: "kovan",
    networkId: 42,
    configUrl: "https://config.dodoex.io/configs/kovan-dev.json",
    defaultEndpointUrl:
      "https://eth-proxy.dodoex.io/v3/d4470e7b7221494caaaa66d3a353c5dc",
    defaultTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/autarkxu/dodoex-v2",
    defaultTokenTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/autarkxu/dodoex-token",
    defaultVDODOTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/autarkxu/dodoex-vdodo",
    multicallAddr: DODO_SDK_DATA.MULTI_CALL_KOVAN,
    chiAddress: "0x0000000000004946c0e9f43f4dee607b0ef1fa1c",
    fromTokenInit: {
      symbol: "WETH",
      address: "0x5eca15b12d959dfcf9c71c59f8b467eb8c6efd0b",
      name: "WETH",
      decimals: 18,
      source: "dodo",
    },
    toTokenInit: {
      symbol: "USDC",
      address: "0x43688f367eb83697c3ca5d03c5055b6bd6f6ac4b",
      name: "USD Coin",
      decimals: 6,
      source: "dodo",
    },
    scanUrlDomain: "kovan.etherscan.io",
    etherscanApiUrl: "api-kovan.etherscan.io",
    dodoApproveProxyV2: DODO_SDK_DATA.DODO_APPROVE_KOVAN,
    routeV1DataFetch: DODO_SDK_DATA.ROUTE_V1_DATA_FETCH_KOVAN,
    blockCountPerDay: 6700,
    tradeIncentive: true,
    blockTime: 13000,
  },
  // BSC
  // mainnet
  56: {
    name: "Binance Smart Chain Mainnet",
    chainId: 56,
    chain: "BSC",
    network: "mainnet",
    networkId: 56,
    configUrl: "https://config.dodoex.io/configs/bsc.json",
    defaultEndpointUrl: "https://bsc-dataseed.binance.org",
    defaultEndpointWS: "wss://bsc-ws-node.nariox.org:443",
    defaultTheGraphUrl:
      "https://pq.hg.network/subgraphs/name/dodoex/dodoex-v2-bsc",
    defaultTokenTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-token-bsc",
    defaultVDODOTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-vdodo-bsc",
    multicallAddr: DODO_SDK_DATA.MULTI_CALL_BSC,
    fromTokenInit: {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      decimals: 18,
      showDecimals: 6,
    },
    toTokenInit: {
      symbol: "USDT",
      address: "0x55d398326f99059ff775485246999027b3197955",
      name: "Tether USD",
      decimals: 18,
      showDecimals: 6,
    },
    scanUrlDomain: "bscscan.com",
    dodoApproveProxyV2: DODO_SDK_DATA.DODO_APPROVE_BSC,
    routeV1DataFetch: DODO_SDK_DATA.ROUTE_V1_DATA_FETCH_BSC,
    blockCountPerDay: 6700 * 5,
    tradeIncentive: false,
    blockTime: 3000,
  },
  86: {
    name: "Binance Smart Chain Mainnet",
    chainId: 86,
    chain: "BSC",
    network: "mainnet",
    networkId: 86,
    configUrl: "https://config.dodoex.io/configs/bsc.json",
    defaultEndpointUrl: "https://bsc-dataseed.binance.org",
    defaultEndpointWS: "wss://bsc-ws-node.nariox.org:443",
    defaultTheGraphUrl:
      "https://pq.hg.network/subgraphs/name/dodoex/dodoex-v2-bsc",
    defaultTokenTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-token-bsc",
    defaultVDODOTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/dodoex/dodoex-vdodo-bsc",
    multicallAddr: DODO_SDK_DATA.MULTI_CALL_BSC,
    fromTokenInit: {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      decimals: 18,
      showDecimals: 6,
    },
    toTokenInit: {
      symbol: "USDT",
      address: "0x55d398326f99059ff775485246999027b3197955",
      name: "Tether USD",
      decimals: 18,
      showDecimals: 6,
    },
    scanUrlDomain: "bscscan.com",
    dodoApproveProxyV2: DODO_SDK_DATA.DODO_APPROVE_BSC,
    routeV1DataFetch: DODO_SDK_DATA.ROUTE_V1_DATA_FETCH_BSC,
    blockCountPerDay: 6700 * 5,
    tradeIncentive: false,
    blockTime: 3000,
  },
  // testnet
  97: {
    name: "Binance Smart Chain Testnet",
    chainId: 97,
    chain: "BSC",
    network: "Chapel",
    networkId: 97,
    // TODO BSC testnet configUrl?
    configUrl: "https://config.dodoex.io/configs/bsc-testnet.json",
    defaultEndpointUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    defaultTokenTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/autarkxu/dodoex-token",
    defaultVDODOTheGraphUrl:
      "https://api.thegraph.com/subgraphs/name/autarkxu/dodoex-vdodo",
    fromTokenInit: {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      decimals: 18,
      showDecimals: 6,
    },
    toTokenInit: {
      symbol: "USDT",
      address: "0x55d398326f99059ff775485246999027b3197955",
      name: "Tether USD",
      decimals: 18,
      showDecimals: 6,
    },
    scanUrlDomain: "testnet.bscscan.com",
    routeV1DataFetch: DODO_SDK_DATA.ROUTE_V1_DATA_FETCH_BSC,
    blockCountPerDay: 6700 * 5,
    tradeIncentive: false,
    blockTime: 3000,
  },
};

export const kRPCNodes = {};

export const EtherTokenWithChainId: Record<
  number,
  TokenInterface & { wrappedTokenSymbol: string; wrappedTokenAddress: string }
> = {
  1: {
    symbol: "ETH",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Ether",
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: "WETH",
    wrappedTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  },
  42: {
    symbol: "ETH",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Ether",
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: "WETH",
    wrappedTokenAddress: "0x5eca15b12d959dfcf9c71c59f8b467eb8c6efd0b",
  },
  56: {
    symbol: "BNB",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "BNB",
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: "WBNB",
    wrappedTokenAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  },
  86: {
    symbol: "BNB",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "BNB",
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: "WBNB",
    wrappedTokenAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  },
  97: {
    symbol: "BNB",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "BNB",
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: "WBNB",
    wrappedTokenAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", // BSC测试网没有WBNB，如需测试网支持，需要指定WBNB Token地址
  },
};
