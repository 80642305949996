import React, { ReactElement, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { ReactComponent as CloseImg } from "../images/dialog-close.svg";
import { ReactComponent as BackImg } from "../images/dialog-back.svg";

export const DialogCtx: React.Context<any> = React.createContext({
  onClose: null,
});

type InnerProps = {
  children?: any;
  title?: string | ReactElement;
  onBack?: () => void;
  onClose?: () => void;
  minititle?: boolean;
  slim?: boolean;
  titleLeft?: boolean;
  hideClose?: boolean;
};

type Props = {
  on: boolean;
  maxHeight?: number;
  width?: string | number;
} & InnerProps;

const DialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* react-bootstrap modal z-index=1060 */
  /* react-bootstrap tooltip z-index=1070 */
  z-index: 1065;

  background: rgba(0, 0, 0, 0.8);
  /* backdrop-filter: blur(2px); */

  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogSheet = styled.div`
  max-width: 95%;
  border-radius: 15px;
  background: white;
  ${/* color: ${({ theme }) => theme.palette.mainText}; */ null}
  color: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const DialogHeader = styled.div<{ mini: boolean; left: boolean }>`
  font-size: ${({ mini }) => (mini ? "16px" : "20px")};
  line-height: 28px;
  margin-top: 24px;
  text-align: ${({ left }) => (left ? "left" : "center")};
  margin-left: ${({ left }) => (left ? "34px" : "0")};
  margin-bottom: 12px;
  font-weight: 500;

  ${
    /* color: ${({ mini, theme }) => (mini ? theme.palette.lightText : theme.palette.mainText)}; */ null
  }
  color: black;

  position: relative;
`;

const DialogBack = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  left: 34px;
  cursor: pointer;

  svg {
    width: 15px;
    ${/* fill: ${({ theme }) => theme.palette.lightText}; */ null}
    fill: black;
  }
`;

const DialogClose = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  right: 27px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    ${/* fill: ${({ theme }) => theme.palette.lightText}; */ null}
    fill: black;
  }
`;

const DialogBody = styled.div`
  padding: ${({ slim }: { slim: boolean }) =>
    slim ? "0 20px 20px 20px" : "0 34px 24px 34px"};
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

function DialogInnerImpl({
  children,
  title,
  onBack,
  onClose,
  minititle = false,
  slim = false,
  titleLeft = false,
  hideClose = false,
}: InnerProps): React.ReactElement {
  return (
    <>
      <DialogHeader mini={minititle} left={titleLeft}>
        {onBack && (
          <DialogBack onClick={onBack}>
            <BackImg style={{}} />
          </DialogBack>
        )}
        {title}
        {onClose && !hideClose && (
          <DialogClose onClick={onClose}>
            <CloseImg />
          </DialogClose>
        )}
      </DialogHeader>
      <DialogBody slim={slim}>{children}</DialogBody>
    </>
  );
}

export const DialogInner = React.memo(DialogInnerImpl);

function Dialog({
  on,
  onClose,
  width = "360px",
  maxHeight,
  ...rest
}: Props): React.ReactElement {
  const blocker = useCallback((e) => e.stopPropagation(), []);
  const ctx = {
    onClose,
  };

  return (
    <CSSTransition in={on} timeout={200} classNames="fade" unmountOnExit>
      <DialogCtx.Provider value={ctx}>
        <DialogBackdrop onClick={onClose}>
          <DialogSheet onClick={blocker} style={{ width, maxHeight }}>
            <DialogInner {...rest} onClose={onClose} />
          </DialogSheet>
        </DialogBackdrop>
      </DialogCtx.Provider>
    </CSSTransition>
  );
}

export default React.memo(Dialog);
