import { cloneDeep } from "lodash";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import cookies from "./cookie";

const adapter = new LocalStorage("dodoex-app");
const db = low(adapter);

db.defaults({
  sys: {},
  database: {},
}).write();

export default db;

/**
 * Checks if the path exists and initializes if it doesn't.
 */
export function pathInit({
  dbName = "database",
  path = "",
  user = true,
  validator = () => true,
  defaultValue = "",
}: Record<string, any>): string {
  const uuid = cookies.get("uuid") || "ghost-uuid";
  const currentPath = `${dbName}.${user ? `user.${uuid}` : "public"}${
    path ? `.${path}` : ""
  }`;
  const value = db.get(currentPath).value();
  if (!(value !== undefined && validator(value))) {
    db.set(currentPath, defaultValue).write();
  }
  return currentPath;
}

/**
 * Store data to a specific location
 * Path does not exist will be initialized automatically
 */
export function dbSet<T>({
  dbName = "database",
  path = "",
  value,
  user = false,
}: {
  dbName?: string;
  path: string;
  value?: T;
  user?: boolean;
}) {
  db.set(
    pathInit({
      dbName,
      path,
      user,
    }),
    value
  ).write();
}

/**
 * Get data
 */
export function dbGet<T>({
  dbName = "database",
  path = "",
  defaultValue,
  user = false,
}: {
  dbName?: string;
  path: string;
  defaultValue?: T;
  user?: boolean;
}) {
  return cloneDeep(
    db
      .get(
        pathInit({
          dbName,
          path,
          user,
          defaultValue,
        })
      )
      .value()
  );
}

/**
 * Obtaining Storage Database Objects
 */
export function database({
  dbName = "database",
  path = "",
  user = false,
  validator = () => true,
  defaultValue = "",
}: Record<string, any> = {}) {
  return db.get(
    pathInit({
      dbName,
      path,
      user,
      validator,
      defaultValue,
    })
  );
}
