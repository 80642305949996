import {
  BIG_ALLOWANCE,
  Token,
  WalletState,
  getCurrentAccount,
  getCurrentChainId,
  getCurrentGasPrice,
} from "dodo-wallet";
// import { getChainConfig } from 'dodo-wallet/build/chainConfigs';
import { useCallback, useEffect, useMemo, useRef } from "react";

import { BigNumber } from "bignumber.js";
// import { isSameAddress } from '../../lib/utils';
import { useSelector } from "react-redux";

export function useGetBalance<S extends WalletState>(): (
  token: Token
) => BigNumber | null {
  const account = useSelector(getCurrentAccount);
  const chainId = useSelector(getCurrentChainId);
  // const EtherToken = useMemo(() => getChainConfig(chainId).basicToken, [chainId]);
  const accountBalances = useSelector((state: S) =>
    state.dodoWallet.get("accountBalances")
  );
  const getBalance = useCallback(
    (token: Token) => {
      if (!account) return null;
      /*
      if (token.get('symbol') === EtherToken.symbol && isSameAddress(token.get('address'), EtherToken.address))
        return accountBalances.get(account)?.get('ethBalance') ?? null;
      */
      return (
        accountBalances
          .get(account)
          ?.get("tokenBalances")
          ?.get(token.get("address").toLowerCase()) ?? null
      );
    },
    [accountBalances, account]
  );

  return getBalance;
}

export function useGetAllowance<S extends WalletState>(
  contract: string | null
): (token: Token) => BigNumber | null {
  const account = useSelector(getCurrentAccount);
  const accountBalances = useSelector((state: WalletState) =>
    state.dodoWallet.get("accountBalances")
  );
  const chainId = useSelector(getCurrentChainId);
  // const EtherToken = useMemo(() => getChainConfig(chainId).basicToken, [chainId]);
  const getAllowance = useCallback(
    (token: Token) => {
      if (!account || !contract) return null;
      /*
      if (token.get('symbol') === EtherToken.symbol && isSameAddress(token.get('address'), EtherToken.address))
        return new BigNumber(BIG_ALLOWANCE);
      */
      const allowances = accountBalances
        .get(account)
        ?.get("tokenAllowances")
        ?.get(contract);
      return allowances?.get(token.get("address").toLowerCase()) ?? null;
    },
    [accountBalances, account, contract]
  );

  return getAllowance;
}

export function useNormalizedGasPrice<S extends WalletState>() {
  const rapidGasPrice = useSelector((state: S) =>
    state.dodoWallet.get("rapidGasPrice")
  );
  const fastGasPrice = useSelector((state: S) =>
    state.dodoWallet.get("fastGasPrice")
  );
  const stdGasPrice = useSelector((state: S) =>
    state.dodoWallet.get("stdGasPrice")
  );
  const gas = useSelector((store: S) => store.dodoWallet.get("gas"));

  const normalizedGasPrice = useMemo(() => {
    return getCurrentGasPrice();
  }, [rapidGasPrice, fastGasPrice, stdGasPrice, gas]);
  return normalizedGasPrice;
}
