import { Redirect, Route, Switch } from "react-router-dom";

import KAKA from "./KAKA";
import React from "react";
import YuiHatano from "./YuiHatano";
import Fear from "./Fear";
export default function MysteryBox() {
  return (
    <Switch>
      <Route
        exact
        path="/mystery"
        render={() => <Redirect to="/mystery/kaka" />}
      />
      <Route path="/mystery/yui-hatano" component={YuiHatano} />
      <Route path="/mystery/kaka" component={KAKA} />
      <Route path="/mystery/fear" component={Fear} />
    </Switch>
  );
}
