import React, { useMemo, useState } from "react";
import { Image } from "antd";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { normalizeUri } from "@Utils/net";
import {
  MysteryBoxType,
  useNftData,
  useNftOwner,
  useNtfWithTokenId,
} from "./actions";
import { AddressWithArrowLink } from "@Components/AddressLinkWithArrow";
import { generateProxyUrl } from "../../lib/imgProxy";

const Frame = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  max-width: 1440px;
  margin: auto;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const Center = styled.div`
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    order: 0;
  }
`;

const Title = styled.div`
  padding: 30px 20px 0 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  border-bottom: #e1e1e1 1px solid;
`;

const NFTs = styled.div`
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
`;

const Cont = styled.div`
  width: 320px;
  border-right: #e1e1e1 1px solid;
  padding: 20px;
  @media screen and (max-width: 767px) {
    width: auto;
    border-right: 0;
    order: 1;
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Username = styled.div`
  font-size: 20px;
  font-weight: 800;
  flex: 1;
  color: black;
`;

const Desc = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
`;

const Metadata = styled.div`
  font-size: 16px;
  font-weight: 800;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

interface Props {
  type: MysteryBoxType;
}

export default function Detail({ type }: Props) {
  const { tokenId } = useParams<{ tokenId: string }>();
  const nft = useNtfWithTokenId(type, tokenId);
  const { owner } = useNftOwner(type, tokenId);
  const data = useNftData(nft);
  const renderMedia = useMemo(() => {
    if (!data) return <Skeleton />;
    if (data) {
      return (
        <>
          {data.animation_url ? (
            <video
              src={normalizeUri(data.animation_url, true)}
              loop
              autoPlay
              controls
              playsInline
              x5-video-player-fullscreen
              x5-playsinline
              webkit-playsinline
              style={{ maxWidth: "100%" }}
            />
          ) : (
            <Image
              src={generateProxyUrl({
                url: normalizeUri(data.image),
                width: "1000",
                height: "1000",
              })}
              preview={true}
            />
          )}
        </>
      );
    }
  }, [data]);
  return (
    <Frame>
      <Cont>
        <User>{data ? <Username>{data.name}</Username> : <Skeleton />}</User>

        <Metadata>
          <div style={{ marginRight: 4 }}>Owner:</div>
          {owner ? <AddressWithArrowLink address={owner} /> : <Skeleton />}
        </Metadata>
        <Desc>{data ? data.description : <Skeleton />}</Desc>

        {data ? (
          data.attributes.map(({ trait_type, value }) => (
            <Metadata key={trait_type}>
              {trait_type}: {value}
            </Metadata>
          ))
        ) : (
          <Skeleton />
        )}
      </Cont>
      <Center>
        <NFTs>{renderMedia}</NFTs>
      </Center>
    </Frame>
  );
}
