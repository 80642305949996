import i18n from "i18next";
import { chainIdToChainConfig } from "../constants";

/**
 * open etherscan.io or bscscan.com
 * @param path url path
 * @param chainId chain ID
 */
export async function openEtherscanPage(
  path: string,
  chainId: number
): Promise<void> {
  let language = i18n.language;
  language = language.replace("-", "");
  const chainConfig = chainIdToChainConfig[chainId];
  if (chainConfig) {
    let domain =
      typeof chainConfig.scanUrlDomain === "string"
        ? chainConfig.scanUrlDomain
        : chainConfig.scanUrlDomain[language];
    if (!domain) {
      domain = chainConfig.scanUrlDomain["enUS"];
    }
    window.open(`https://${domain}/${path}`, "_blank", "noopener,noreferrer");
  }
}
