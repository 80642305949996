import React from "react";

export const Copy: React.FC = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="16px"
      width="16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="336"
        height="336"
        x="128"
        y="128"
        fill="none"
        strokeLinejoin="round"
        strokeWidth="32"
        rx="57"
        ry="57"
      ></rect>
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24"
      ></path>
    </svg>
  );
};
