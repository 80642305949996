import Button, { ButtonVariant } from "@Components/Button";
import React, { useCallback, useEffect, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { WalletType, connectToWallet, getCurrentAccount } from "dodo-wallet";
import { useDispatch, useSelector } from "react-redux";
import Detail from "../detail";
import { Dropdown, Menu } from "antd";
import Main from "./main";
import { truncatePoolAddress } from "../../../utils";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "../actions";
import { DContent, DHeader, DLayout, LanguageMenu, Spanner } from "./style";
import { DownOutlined } from "@ant-design/icons";
import { logKAKAPageView } from "../../../lib/mixpanel";

const Languages = {
  zhCN: "简体中文",
  enUS: "English",
};

export default function KAKA() {
  const account = useSelector(getCurrentAccount);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const setLanguage = useCallback((language: string) => {
    dispatch({ type: "SET_LANGUAGE", payload: { language } });
    logKAKAPageView();
  }, []);

  useEffect(() => {
    const lang = i18n.language.replace("-", "");
    if (lang !== "enUS" && lang !== "zhCN") {
      dispatch({ type: "SET_LANGUAGE", payload: { language: "enUS" } });
    }
  }, [i18n.language]);

  const renderMenu = useMemo(() => {
    return (
      <LanguageMenu>
        {Object.keys(Languages).map((lang) => {
          return (
            <Menu.Item
              key={lang}
              onClick={() => setLanguage(lang)}
              style={{ padding: "8px 24px" }}
            >
              {Languages[lang]}
            </Menu.Item>
          );
        })}
      </LanguageMenu>
    );
  }, []);

  return (
    <DLayout>
      <DHeader>
        <div className="img" />
        <Spanner />
        <Dropdown overlay={renderMenu}>
          <Button
            variant={ButtonVariant.Yellow}
            style={{ padding: "0 16px", minWidth: 124 }}
          >
            {Languages[i18n.language]}
            <DownOutlined style={{ width: 24, marginLeft: 10 }} />
          </Button>
        </Dropdown>

        <nav>
          {account ? (
            <Button variant={ButtonVariant.Yellow}>
              {truncatePoolAddress(account)}
            </Button>
          ) : (
            <Button
              onClick={() => {
                connectToWallet(WalletType.MetaMask);
              }}
              variant={ButtonVariant.Yellow}
            >
              {t("common.connect-wallet")}
            </Button>
          )}
        </nav>
      </DHeader>
      <DContent>
        <Switch>
          <Route exact path="/mystery/kaka" component={Main} />
          <Route exact path="/mystery/kaka/:tokenId">
            <Detail type={MysteryBoxType.KAKA} />
          </Route>
        </Switch>
      </DContent>
    </DLayout>
  );
}
