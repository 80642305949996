import {
  BlackColor,
  BlueColor,
  HighlightYellowColor,
  PurpleColor,
  YellowColor,
} from "./styled";
import {
  MysteryBoxTimestamps,
  MysteryBoxType,
  getTypeI18nKey,
} from "./actions";

import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CardContainer = styled.div<{ type?: MysteryBoxType }>`
  background: ${({ type }) => {
    if (type === MysteryBoxType.Fear) return BlackColor;
    else return "#fff";
  }};
  font-size: 14px;
  max-width: 1440px;
  margin: 30px auto;
  color: ${({ type }) => {
    if (type === MysteryBoxType.Fear) return "#fff";
    else return "#111";
  }};
  border-radius: 12px;
  display: flex;
  z-index: 1;
  position: relative;
`;

const Title = styled.div<{ type?: MysteryBoxType }>`
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px 0 0 8px;
  word-wrap: break-word;
  background: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return YellowColor;
    else if (type === MysteryBoxType.Fear) return HighlightYellowColor;
    else return PurpleColor;
  }};
  color: ${({ type }) => {
    if (type === MysteryBoxType.KAKA) return BlueColor;
    else if (type === MysteryBoxType.Fear) return "#111";
    else return "#fff";
  }};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2;
  padding: 16px;
`;

interface Props {
  type: MysteryBoxType;
}

export default function IntroCard({ type }: Props) {
  const { t } = useTranslation();
  const timestamps = MysteryBoxTimestamps[type];
  return (
    <CardContainer type={type}>
      <Title type={type}>{t("mystery-box.box-info.learn-more")}</Title>

      <Content>
        <div>
          <span>{t("mystery-box.intro-dialog.init-sale-time")}</span>
          <span style={{ marginLeft: 6 }}>
            {moment(timestamps.startTime).format("MM/DD HH:mm")} -{" "}
            {moment(timestamps.endTime).format("MM/DD HH:mm")}
          </span>
        </div>
        <div>
          <span>{t("mystery-box.intro-dialog.open-box-time")}</span>
          <span style={{ marginLeft: 6 }}>
            {moment(timestamps.openTime).format("MM/DD HH:mm")}
            {timestamps.openEndTime && (
              <>
                {" "}
                - {moment(timestamps.openEndTime).format("MM/DD HH:mm")}{" "}
                {t("mystery-box.intro-dialog.open-box-time-tip")}
              </>
            )}
          </span>
        </div>
        <div>
          <span>
            {t(
              `mystery-box.intro-dialog.fans-welfare-desc${getTypeI18nKey(
                type
              )}`
            )}
          </span>
        </div>
        <div>
          <span>
            {t(`mystery-box.intro-dialog.buy-back-desc${getTypeI18nKey(type)}`)}
          </span>
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                `mystery-box.intro-dialog.fans-welfare-desc-2${getTypeI18nKey(
                  type
                )}`
              ),
            }}
          ></span>
        </div>
      </Content>
    </CardContainer>
  );
}
