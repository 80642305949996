import { NavLink } from "react-router-dom";
import styled from "styled-components";

export enum TextLinkVariant {
  Default = "DEFAULT",
  Highlighted = "HIGHLIGHTED",
}

const TEXTLINKCOLOR: Record<TextLinkVariant, string> = {
  [TextLinkVariant.Default]: "#90939A",
  [TextLinkVariant.Highlighted]: "#242428",
};

type Props = {
  variant?: TextLinkVariant;
};

const TextLink = styled(NavLink)<Props>`
  padding-right: 40px;
  width: 32px;
  height: 22px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ variant = TextLinkVariant.Default }) => TEXTLINKCOLOR[variant]};
`;

export default TextLink;
