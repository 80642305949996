import React from "react";
import styled from "styled-components";
import BasicButton from "../common/BasicButton";

export enum ButtonVariant {
  Default,
  Primary,
  Secondary,
  Error,
}

type InnerProps = {
  disabled?: boolean;
  variant?: ButtonVariant;
  spaced?: boolean | number;
  overrideBg?: string;
};

const BaseActionButton = styled(BasicButton)`
  display: block;
  padding: 15px;
  font-size: 20px;
  width: 100%;
  height: auto;
`;

const BORDER_MAPPING = {
  [ButtonVariant.Default]: "#FFE804",
  [ButtonVariant.Primary]: "transparent",
  [ButtonVariant.Secondary]: "transparent",
  [ButtonVariant.Error]: "transparent",
};

const COLOR_MAPPING = {
  [ButtonVariant.Default]: "#FFE804",
  [ButtonVariant.Primary]: "#000000",
  [ButtonVariant.Secondary]: "#ffffff",
  [ButtonVariant.Error]: "#ffffff",
};

const BG_MAPPING = {
  [ButtonVariant.Default]: "transparent",
  [ButtonVariant.Primary]: "#FFE804",
  [ButtonVariant.Secondary]: "#3185ff",
  [ButtonVariant.Error]: "#FF4F73",
};

const HOVER_BG_MAPPING = {
  [ButtonVariant.Default]: "rgba(255,232,4,9%)",
  [ButtonVariant.Primary]: "#FFF169",
  [ButtonVariant.Secondary]: "#4590FF",
  [ButtonVariant.Error]: "#FFAEC8", // TODO: change me
};

const DISABLED_BG_OVERRIDE = {
  [ButtonVariant.Default]: "transparent",
  [ButtonVariant.Error]: "#FF849C",
};

const DISABLED_COLOR_OVERRIDE = {
  [ButtonVariant.Error]: "#FFCED8",
};

const ActionButton = styled(BaseActionButton)<InnerProps>`
  font-weight: 500;
  margin-top: ${({ spaced = false }) => {
    if (spaced === false) return 0;
    else if (spaced === true) return 10;
    return spaced;
  }}px;

  margin-bottom: ${({ spaced = false }) => {
    if (spaced === false) return 0;
    else if (spaced === true) return 10;
    return spaced;
  }}px;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ variant = ButtonVariant.Default }) =>
    BORDER_MAPPING[variant]};

  color: ${({ variant = ButtonVariant.Default }) => COLOR_MAPPING[variant]};

  background-color: ${({
    variant = ButtonVariant.Default,
    overrideBg,
    theme,
  }) =>
    overrideBg ??
    (variant === ButtonVariant.Primary ? theme.right?.primaryColor : null) ??
    BG_MAPPING[variant]};

  &:focus {
    /* Meow: Force override everything */
    background-color: ${({
      variant = ButtonVariant.Default,
      overrideBg,
      theme,
    }) =>
      overrideBg ??
      (variant === ButtonVariant.Primary ? theme.right?.primaryColor : null) ??
      BG_MAPPING[variant]};
    color: ${({ variant = ButtonVariant.Default }) => COLOR_MAPPING[variant]};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ variant = ButtonVariant.Default }) =>
      BORDER_MAPPING[variant]};
  }

  &:disabled {
    background: ${({ variant = ButtonVariant.Default, theme }) =>
      DISABLED_BG_OVERRIDE[variant] ?? "black"};
    color: ${({ variant = ButtonVariant.Default, theme }) =>
      DISABLED_COLOR_OVERRIDE[variant] ?? "white"};
    border-color: ${({ variant = ButtonVariant.Default }) =>
      BORDER_MAPPING[variant]};
  }

  &:hover:enabled {
    background-color: ${({ variant = ButtonVariant.Default, theme }) =>
      (variant === ButtonVariant.Primary ? theme.right?.primaryColor : null) ??
      HOVER_BG_MAPPING[variant]};

    color: ${({ variant = ButtonVariant.Default }) => COLOR_MAPPING[variant]};

    border-width: 1px;
    border-style: solid;
    border-color: ${({ variant = ButtonVariant.Default }) =>
      BORDER_MAPPING[variant]};
  }
`;

export const InnerDefaultActionButton = styled(BaseActionButton)`
  color: #ffffff;
  background-color: #2c73db;
  border: 1px solid #2c73db;
  font-weight: 500;

  &:disabled {
    color: #cccccc;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
  }

  &:focus,
  &:active,
  &:hover:enabled {
    background-color: #2c73db;
    border: 1px solid #ffffff;
  }
`;
export const DefaultActionButton = React.memo(InnerDefaultActionButton);

export default ActionButton;

export const ActionButtonRow = styled.div`
  display: flex;

  ${ActionButton} {
    flex: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
  ${InnerDefaultActionButton} {
    flex: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
