import React, { useMemo, useState } from "react";
import BoxCover from "../../assets/box-cover.png";
import BoxCoverKAKA from "../../assets/box-cover-kaka.png";
import BoxCoverFearVideo from "../../assets/blindbox.mp4";
import { MysteryBoxType } from "./actions";

interface Props {
  imgUrl: string;
  type: MysteryBoxType;
}

export default function BigSizeImage({ imgUrl, type }: Props) {
  const [showDefault, setShowDefault] = useState<boolean>(true);
  const image = useMemo(() => {
    if (type === MysteryBoxType.KAKA)
      return (
        <img
          width="100%"
          src={BoxCoverKAKA}
          style={{ opacity: showDefault ? "1" : "0" }}
        />
      );
    else if (type === MysteryBoxType.Fear)
      return (
        <video
          width="272"
          src={BoxCoverFearVideo}
          loop
          autoPlay
          muted
          playsInline
          x5-video-player-fullscreen
          x5-playsinline
          webkit-playsinline
        ></video>
      );
    else
      return (
        <img
          width="100%"
          src={BoxCover}
          style={{ opacity: showDefault ? "1" : "0" }}
        />
      );
  }, [type, showDefault]);
  return (
    <>
      {image}
      <img
        width="100%"
        src={imgUrl}
        style={{
          cursor: "pointer",
          opacity: showDefault ? "0" : "1",
          transition: "opacity 2s",
        }}
        onLoad={() => setShowDefault(false)}
      />
    </>
  );
}
