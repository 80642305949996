import { List, Map, OrderedMap } from "immutable";
import {
  getCachedContract,
  getCurrentAccount,
  getCurrentChainId,
  getCurrentGasPrice,
  getEstimateGas,
  getLatestBlockNumber,
  prepareChainEndpointUrl,
  sendTransaction,
  watchTransaction,
} from "dodo-wallet";
import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { Contract } from "web3-eth-contract";
import { RootState } from "src/store";
import Web3 from "web3";
import abi from "./abi";
import axios from "axios";
import { normalizeUri } from "@Utils/net";
import { useSelector } from "react-redux";

export enum MysteryBoxType {
  YuiHatano,
  KAKA,
  Fear,
}

export const getTypeI18nKey = (type: MysteryBoxType) => {
  switch (type) {
    case MysteryBoxType.KAKA:
      return ".kaka";
    case MysteryBoxType.Fear:
      return ".fear";
    default:
      return "";
  }
};

export const getTypeRoutePath = (type: MysteryBoxType) => {
  switch (type) {
    case MysteryBoxType.KAKA:
      return "kaka";
    case MysteryBoxType.Fear:
      return "fear";
    default:
      return "yui-hatano";
  }
};

export const MysteryBoxTimestamps = {
  [MysteryBoxType.YuiHatano]: {
    startTime: 1620306000000,
    endTime: 1620648000000,
    openTime: 1620388800000,
    openEndTime: 1620648000000,
  },
  [MysteryBoxType.KAKA]: {
    startTime: 1622206800000,
    endTime: 1622379600000,
    openTime: 1622466000000,
    openEndTime: 1622725200000,
  },
  [MysteryBoxType.Fear]: {
    startTime: 1623063600000,
    endTime: 1623236400000,
    openTime: 1623236400000,
    openEndTime: 1623495600000,
  },
};

export const MysteryBoxProductionChainId = {
  [MysteryBoxType.YuiHatano]: 56,
  [MysteryBoxType.KAKA]: 56,
  [MysteryBoxType.Fear]: 56,
};

export const MysteryBoxContractAddress = {
  [MysteryBoxType.YuiHatano]: {
    1: "",
    42: "0xbfb19a7e788b2fbC47Bc9013694C6854Ce0e1fAd",
    56: "0xc25286ef3BaE3f6Fe2d6d0A6e2acAd0301AF97b8",
    86: "0xc25286ef3BaE3f6Fe2d6d0A6e2acAd0301AF97b8",
  },
  [MysteryBoxType.KAKA]: {
    1: "",
    42: "0xd56Fd300aE2e4C46cd34460776007dCE1C4F2043",
    56: "0xDf7E00Cd0bb91D1502a1A14575E58b5d8f20C8D4",
    86: "0xDf7E00Cd0bb91D1502a1A14575E58b5d8f20C8D4",
  },
  [MysteryBoxType.Fear]: {
    1: "",
    4: "0xff29114d297Fd2945636239E09Ab8333dA5601eA",
    42: "",
    56: "0x3E629332c51046a17ec236553cB931CF0548B5e1",
    86: "0x3E629332c51046a17ec236553cB931CF0548B5e1",
  },
};

export const useMysteryBoxContractInfo = (type: MysteryBoxType) => {
  const [currentPrice, setCurrentPrice] = useState<BigNumber>(
    new BigNumber(NaN)
  );
  const [currentSellingTickets, setCurrentSellingTickets] =
    useState<number>(NaN);
  const [redeemAllowed, setRedeemAllowed] = useState<boolean>(false);

  const blockNumber = useSelector(getLatestBlockNumber);
  const contract = useMysteryContract(type);

  const refetch = useCallback(() => {
    if (!contract) return;
    contract.methods
      ._CUR_PRCIE_()
      .call()
      .then((result) => setCurrentPrice(new BigNumber(result).div(1e18)));

    contract.methods
      ._CUR_SELLING_TICKETS_()
      .call()
      .then(setCurrentSellingTickets);

    contract.methods._REDEEM_ALLOWED_().call().then(setRedeemAllowed);
  }, [contract]);

  useEffect(() => {
    refetch();
  }, [blockNumber, refetch]);

  return { currentPrice, currentSellingTickets, redeemAllowed, refetch };
};

export const useMysteryContract = (type: MysteryBoxType) => {
  const [contract, setContract] = useState<Contract>();
  const chainId = useSelector(getCurrentChainId);
  useEffect(() => {
    const contractAddress = MysteryBoxContractAddress[type][chainId];
    if (!contractAddress) return;
    getCachedContract(contractAddress, abi).then(setContract);
  }, [chainId]);
  return contract;
};

export const useWriteContract = (type: MysteryBoxType) => {
  const contract = useMysteryContract(type);
  const chainId = useSelector(getCurrentChainId);
  const account = useSelector(getCurrentAccount);
  const gasPrice = useSelector(getCurrentGasPrice);
  const [loading, setLoading] = useState<boolean>(false);

  const buyTicket = useCallback(
    async (value: BigNumber, callback?: any) => {
      if (!contract || !account) return;
      try {
        const data = contract.methods.buyTicket().encodeABI();
        let params: any = {
          value: Web3.utils.toHex(Web3.utils.toWei(value.toString(), "ether")),
          data,
          to: MysteryBoxContractAddress[type][chainId],
          from: account,
          gasPrice,
        };
        setLoading(true);
        const gasLimit = await getEstimateGas(params);
        params.gasLimit = gasLimit;
        const result = await sendTransaction(params);
        watchTransaction(result, account).then((ret) => {
          if (callback) callback(ret);
        });
        return result;
      } catch (e) {
        console.log("Buy Ticket Error:", e);
        return "";
      } finally {
        setLoading(false);
      }
    },
    [contract, chainId, account, gasPrice]
  );

  const redeemPrize = useCallback(
    async (amount: number, callback?: any) => {
      if (!contract || !account) return;
      try {
        const data = contract.methods.redeemPrize(amount).encodeABI();
        let params: any = {
          data,
          to: MysteryBoxContractAddress[type][chainId],
          from: account,
          gasPrice,
        };
        setLoading(true);
        const gasLimit = await getEstimateGas(params);
        params.gasLimit = gasLimit;
        const result = await sendTransaction(params);
        watchTransaction(result, account).then((ret) => {
          if (callback) callback(ret);
        });
        return result;
      } catch (e) {
        console.log("Redeem Prize Error:", e);
        return "";
      } finally {
        setLoading(false);
      }
    },
    [contract, chainId, account, gasPrice]
  );

  return { buyTicket, redeemPrize, loading };
};

export const useTicketsCount = (type: MysteryBoxType) => {
  const contract = useMysteryContract(type);
  const account = useSelector(getCurrentAccount);
  const blockNumber = useSelector(getLatestBlockNumber);
  const [count, setCount] = useState<number>(NaN);
  const refetch = useCallback(() => {
    if (!contract || !account) return;
    contract.methods.getTickets(account).call().then(setCount);
  }, [contract, account]);

  useEffect(() => {
    refetch();
  }, [refetch, blockNumber]);

  return { count, refetch };
};

let requestTokenID = Map();
let requestTokenURI = Map();
export const useNftList = (type: MysteryBoxType) => {
  const contract = useMysteryContract(type);
  const account = useSelector(getCurrentAccount);
  const blockNumber = useSelector(getLatestBlockNumber);
  const [nftList, setNftList] = useState<OrderedMap<string, string>>(
    OrderedMap()
  );
  const [nftCount, setNftCount] = useState<number>(NaN);
  const [tokenIDList, setTokenIDList] = useState<List<string>>(List());
  const [latestNft, setLatestNft] = useState<string>(null);
  const resetLatestNft = useCallback(() => setLatestNft(null), []);
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(() => {
    if (!contract || !account) return;
    contract.methods.balanceOf(account).call().then(setNftCount);
  }, [contract, account]);

  useEffect(() => {
    if (isNaN(nftCount) || !account) return;
    for (let i = 0; i < nftCount; i++) {
      if (!requestTokenID.get(i)) {
        requestTokenID = requestTokenID.set(i, true);
        contract.methods
          .tokenOfOwnerByIndex(account, i)
          .call()
          .then((result) => setTokenIDList((list) => list.push(result)));
      }
    }
  }, [nftCount, contract, account]);

  const loadNftList = useCallback(async () => {
    if (tokenIDList.size === 0 || !account) return;
    setLoading(true);
    await Promise.all(
      tokenIDList.map(async (tokenID) => {
        if (!requestTokenURI.get(tokenID)) {
          requestTokenURI = requestTokenURI.set(tokenID, true);
          const result = await contract.methods.tokenURI(tokenID).call();
          setNftList((list) => list.set(tokenID, result));
          setLatestNft(result);
        }
      })
    );
    setLoading(false);
  }, [contract, tokenIDList, account, nftCount]);

  useEffect(() => {
    loadNftList();
  }, [tokenIDList, contract, account]);

  useEffect(() => {
    refetch();
  }, [refetch, blockNumber]);

  return { nftList, refetch, latestNft, resetLatestNft, loading };
};

interface NFTAttribute {
  trait_type: string;
  value: string;
}

interface NFTData {
  description: string;
  external_url: string;
  image: string;
  name: string;
  attributes: NFTAttribute[];
  animation_url?: string;
}

let cachedJSON: Map<string, NFTData> = Map();
export const useNftData = (nftUrl: string) => {
  const [data, setData] = useState<NFTData>();
  useEffect(() => {
    if (!nftUrl) return setData(null);
    if (cachedJSON.get(nftUrl)) return setData(cachedJSON.get(nftUrl));
    const ipfsUrl = normalizeUri(nftUrl, true);
    axios.get(ipfsUrl).then((res) => {
      const metajson = res.data;
      cachedJSON = cachedJSON.set(nftUrl, metajson);
      setData(metajson);
    });
  }, [nftUrl]);
  return data;
};

export const useNtfWithTokenId = (type: MysteryBoxType, tokenId: string) => {
  const [nft, setNft] = useState<string>();
  const [contract, setContract] = useState<Contract>();
  const endpoint = useSelector((state: RootState) =>
    prepareChainEndpointUrl(MysteryBoxProductionChainId[type], true, state)
  );

  useEffect(() => {
    const contractAddress =
      MysteryBoxContractAddress[type][MysteryBoxProductionChainId[type]];
    if (!contractAddress) return;
    const web3 = new Web3(endpoint);
    const contract = new web3.eth.Contract(abi, contractAddress);
    setContract(contract);
  }, [endpoint]);

  useEffect(() => {
    if (!contract || !tokenId) return;
    contract.methods.tokenURI(tokenId).call().then(setNft);
  }, [contract, tokenId]);
  return nft;
};

export const useNftOwner = (type: MysteryBoxType, tokenId: string) => {
  const [owner, setOwner] = useState<string | undefined>();
  const [contract, setContract] = useState<Contract>();
  const endpoint = useSelector((state: RootState) =>
    prepareChainEndpointUrl(MysteryBoxProductionChainId[type], true, state)
  );
  useEffect(() => {
    const contractAddress =
      MysteryBoxContractAddress[type][MysteryBoxProductionChainId[type]];
    if (!contractAddress) return;
    const web3 = new Web3(endpoint);
    const contract = new web3.eth.Contract(abi, contractAddress);
    setContract(contract);
  }, [endpoint]);

  const refetch = useCallback(() => {
    if (!contract) return;
    contract.methods
      .ownerOf(tokenId)
      .call()
      .then((result) => setOwner(result));
  }, [contract]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { owner, refetch };
};
