import Button, { ButtonVariant } from "@Components/Button";
import React, { useCallback, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { WalletType, connectToWallet, getCurrentAccount } from "dodo-wallet";
import { useDispatch, useSelector } from "react-redux";
import Detail from "../detail";
import { Dropdown, Menu } from "antd";
import Logo from "../../../assets/logo.png";
import Main from "./main";
import { truncatePoolAddress } from "../../../utils";
import { useTranslation } from "react-i18next";
import { MysteryBoxType } from "../actions";
import { DContent, DHeader, DLayout, LanguageMenu, Spanner } from "./style";
import { DownOutlined } from "@ant-design/icons";

const Languages = {
  zhCN: "简体中文",
  enUS: "English",
  jaJP: "日本語",
};

export default function YuiHatano() {
  const account = useSelector(getCurrentAccount);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const setLanguage = useCallback((language: string) => {
    dispatch({ type: "SET_LANGUAGE", payload: { language } });
  }, []);

  const renderMenu = useMemo(() => {
    return (
      <LanguageMenu>
        {Object.keys(Languages).map((lang) => {
          return (
            <Menu.Item
              key={lang}
              onClick={() => setLanguage(lang)}
              style={{ padding: "8px 24px" }}
            >
              {Languages[lang]}
            </Menu.Item>
          );
        })}
      </LanguageMenu>
    );
  }, []);

  return (
    <DLayout>
      <DHeader>
        <img src={Logo} alt="DODO NFT" />
        <Spanner />
        <Dropdown overlay={renderMenu}>
          <Button
            variant={ButtonVariant.Purple}
            style={{ padding: "0 16px", minWidth: 124 }}
          >
            {Languages[i18n.language]}
            <DownOutlined style={{ width: 24, marginLeft: 10 }} />
          </Button>
        </Dropdown>

        <nav>
          {account ? (
            <Button variant={ButtonVariant.Purple}>
              {truncatePoolAddress(account)}
            </Button>
          ) : (
            <Button
              onClick={() => {
                connectToWallet(WalletType.MetaMask);
              }}
              variant={ButtonVariant.Purple}
            >
              {t("common.connect-wallet")}
            </Button>
          )}
        </nav>
      </DHeader>
      <DContent>
        <Switch>
          <Route exact path="/mystery/yui-hatano" component={Main} />
          <Route exact path="/mystery/yui-hatano/:tokenId">
            <Detail type={MysteryBoxType.YuiHatano} />
          </Route>
        </Switch>
      </DContent>
    </DLayout>
  );
}
