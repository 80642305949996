export const renderTitle = (
  title: string | undefined,
  translation: (key: string) => string
) => {
  if (!title) return "";

  return title.split(" ").reduce((pre, next) => {
    pre += translation(next);
    return pre;
  }, "");
};

export const renderSubtitle = (
  subTitle: string | undefined,
  translation: (key: string, params: Record<string, unknown>) => string
) => {
  if (!subTitle) return subTitle;

  let data: { key: string; [propsName: string]: any };

  try {
    data = JSON.parse(subTitle);
  } catch (error) {
    return subTitle;
  }

  const { key, ...params } = data;

  if (key && typeof key === "string") {
    return translation(key, params);
  } else {
    throw new TypeError("key is not a string");
  }
};
