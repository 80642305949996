import Button, { ButtonVariant } from "@Components/Button";
import { formatReadableNumber } from "@Utils/formatter";
import React, { useCallback, useMemo } from "react";
import {
  getTypeI18nKey,
  MysteryBoxTimestamps,
  MysteryBoxType,
  useWriteContract,
} from "./actions";
import { ThumbnailImgWrapper, ThumbnailWrapper } from "./styled";
import EmptyImage from "../../assets/empty-box.png";
import EmptyImageKAKA from "../../assets/empty-box-kaka.png";
import EmptyImageFear from "../../assets/empty-box-fear.png";
import BoxCover from "../../assets/box-cover.png";
import KAKABoxCover from "../../assets/box-cover-kaka.png";
import BoxCoverFearVideo from "../../assets/blindbox.mp4";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
  logOpenMysteryBox,
  logOpenMysteryBoxFinished,
} from "../../lib/mixpanel";

interface Props {
  ticketsCount: number;
  refetchTicketsCount: () => void;
  refetchNftList: () => void;
  showSuccess: () => void;
  resetLatestNft: () => void;
  redeemAllowed: boolean;
  type: MysteryBoxType;
}

export default function Thumbnail({
  ticketsCount,
  refetchTicketsCount,
  refetchNftList,
  showSuccess,
  resetLatestNft,
  redeemAllowed,
  type,
}: Props) {
  const { redeemPrize, loading } = useWriteContract(type);
  const { t } = useTranslation();
  const handleSubmit = useCallback(async () => {
    logOpenMysteryBox();
    try {
      resetLatestNft();
      const result = await redeemPrize(1, (ret) => {
        logOpenMysteryBoxFinished(!!ret);
        refetchTicketsCount();
        refetchNftList();
      });
      if (result) showSuccess();
    } catch (e) {
      console.log("Buy Ticket Error:", e);
    }
  }, [redeemPrize, refetchTicketsCount]);
  const variant = useMemo(() => {
    if (type === MysteryBoxType.KAKA) return ButtonVariant.Yellow;
    else if (type === MysteryBoxType.Fear) return ButtonVariant.Default;
    else return ButtonVariant.Purple;
  }, [type]);
  const [image, emptyImage] = useMemo(() => {
    if (type === MysteryBoxType.KAKA)
      return [<img src={KAKABoxCover} />, EmptyImageKAKA];
    else if (type === MysteryBoxType.Fear)
      return [
        <video
          src={BoxCoverFearVideo}
          loop
          autoPlay
          muted
          playsInline
          x5-video-player-fullscreen
          x5-playsinline
          webkit-playsinline
        ></video>,
        EmptyImageFear,
      ];
    else return [<img src={BoxCover} />, EmptyImage];
  }, [type]);
  const timestamps = MysteryBoxTimestamps[type];
  return (
    <>
      {ticketsCount > 0 ? (
        <ThumbnailWrapper type={type}>
          <ThumbnailImgWrapper>{image}</ThumbnailImgWrapper>
          <div
            style={{
              padding: 24,
              zIndex: 0,
              position: "relative",
              width: "100%",
            }}
          >
            <div style={{ marginBottom: 12 }}>
              {t(`mystery-box.nft-card.name${getTypeI18nKey(type)}`)}
            </div>
            <div style={{ marginBottom: 12 }}>
              {t("mystery-box.thumbnail.tickets-amount")}
              {t("colon")}
              {formatReadableNumber({ input: ticketsCount, showDecimals: 0 })}
            </div>
            <div style={{ marginBottom: 24, fontSize: 12 }}>
              {t("mystery-box.thumbnail.start-time")}
              {t("colon")}
              {moment(timestamps.openTime).format("MM/DD HH:mm")} -{" "}
              {moment(timestamps.openEndTime).format("MM/DD HH:mm")}
              {t("mystery-box.thumbnail.start-time-tip")}
            </div>
            {ticketsCount > 0 && (
              <Button
                onClick={handleSubmit}
                variant={variant}
                disabled={!redeemAllowed}
              >
                {loading
                  ? t("common.confirm-wallet")
                  : t("mystery-box.thumbnail.open-button")}
              </Button>
            )}
          </div>
        </ThumbnailWrapper>
      ) : (
        <div>
          <div style={{ marginTop: 37, marginBottom: 37 }}>
            <img width="108" src={emptyImage} />
          </div>
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            {t("mystery-box.thumbnail.empty")}
          </div>
        </div>
      )}
    </>
  );
}
