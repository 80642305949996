import "antd/dist/antd.css";
import "./i18n";

import { useTranslation } from "react-i18next";
import store, { RootState } from "./store";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import Button, { ButtonVariant } from "@Components/Button";
import ConnectWallet from "@Components/ConnectWallet";
import {
  NavLink,
  Route,
  BrowserRouter as Router,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import {
  Provider as ReduxProvider,
  useSelector,
  useDispatch,
} from "react-redux";
import {
  WalletType,
  connectToWallet,
  getCurrentAccount,
  useDODOWallet,
  loadGasPrice,
  initWallet,
} from "dodo-wallet";

import { ReactComponent as Icon } from "./assets/icon.svg";

import Detail from "@Pages/detail";
import { Layout, Modal } from "antd";
import List from "@Pages/list";
import Profile from "@Pages/profile";
import React, { useState, useEffect, useCallback } from "react";
import TextLink from "@Components/TextLink";
import styled, { ThemeProvider } from "styled-components";
import { createIOPAccount, loadIOPAccount } from "./actions/follow";
import MysteryBox from "@Pages/mysteryBox";
import { WithExecutionDialog } from "./external/feutils/Submission";
import { WithNotifArea } from "./external/feutils/Notif";
import Create from "@Pages/create";
import New from "@Pages/create/new";
import Import from "@Pages/create/import";
import { getTheme } from "@Components/raw/theme";
import { OldDrops } from "@Pages/old-drops";
import mixpanel from "mixpanel-browser";

mixpanel.init("508488b01b65b2d2616a3d0a96e22510");

const { Header, Content, Footer } = Layout;

const DHeader = styled(Header)`
  height: 64px;
  border-bottom: #e1e1e1 1px solid;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  background: #f9f9f9;
  /* position: fixed;
  left: 0;
  right: 0;
  top: 0; */

  ${Button} {
    margin-left: 24px;
  }
`;

const Spanner = styled.div`
  flex: 1;
`;

const DContent = styled(Content)`
  background: #ffffff;
`;

const StyledIcon = styled(Icon)``;

initWallet({
  projectWalletConfig: {
    ETH_NETWORK: "bsc",
  },
  customChainConfig: {
    1: {
      multicallAddr: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    },
    42: {
      multicallAddr: "0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a",
    },
    56: {
      multicallAddr: "0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb",
    },
    86: {
      multicallAddr: "0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb",
    },
    128: {
      multicallAddr: "0x76a328B3440B9276a31523e731B34082df7a6f1A",
    },
  },
  projectStore: store,

  projectMixpanel: mixpanel,
});

function RootInner(props) {
  const { t } = useTranslation();
  const account = useSelector(getCurrentAccount);
  const [connectingWallet, setConnectingWallet] = useState(false);

  useEffect(() => {
    if (account) setConnectingWallet(false);
  }, [account]);

  // useEffect(() => {
  //   if (!account) return;
  //   loadIOPAccount(account).then((iopAccount) => {
  //     if (!iopAccount) createIOPAccount(account);
  //   });
  // }, [account]);

  useDODOWallet();

  useEffect(() => {
    async function loaderLoop() {
      // eslint-disable-next-line
      while (true) {
        loadGasPrice();
        await new Promise((resolve) => setTimeout(resolve, 15000));
      }
    }
    loaderLoop();
  }, []);

  const dispatch = useDispatch();

  const language = useSelector((state: RootState) => state.settings.language);
  const toggleLanguage = useCallback(() => {
    let target: string;
    if (language === "zh-CN") target = "en-US";
    else target = "zh-CN";
    dispatch({
      type: "SET_LANGUAGE",
      payload: { language: target },
    });
  }, [language]);

  return (
    <Router basename="/">
      <Switch>
        <Route exact path="/">
          <OldDrops />
        </Route>
        <Route path="/mystery" component={MysteryBox} />
        {/* <Layout>
          <DHeader>
            <StyledIcon />
            <Spanner />
            <nav>
              <TextLink to="/">{t("nav.explore")}</TextLink>
              {account && (
                <TextLink to={`/profile/${account}`}>个人中心</TextLink>
              )}
              <TextLink to="/mystery">盲盒</TextLink>
              <Button onClick={toggleLanguage}>{language}</Button>
              <Button>碎片化</Button>
              <Link to="/create">
                <Button variant={ButtonVariant.Highlighted}>创建 NFT</Button>
              </Link>
              {!account && (
                <Button
                  onClick={() => {
                    setConnectingWallet(true);
                  }}
                  variant={ButtonVariant.Black}
                >
                  关联钱包
                </Button>
              )}
            </nav>
          </DHeader>
          <DContent>
            <Switch>
              <Route exact path="/create" component={Create} />
              <Route exact path="/create/new" component={New} />
              <Route exact path="/create/import" component={Import} />

              <Route exact path="/:category?" component={List} />

              <Route
                exact
                path="/detail/:address/:operation(stake)?"
                component={Detail}
              />

              <Route
                exact
                path="/profile/myself/:tab(nft|pieces|staked|liked)?"
                component={Profile}
              />
              <Route
                exact
                path="/profile/:address/:tab(nft|pieces|staked|liked)?"
                component={Profile}
              />
            </Switch>
          </DContent>
        </Layout> */}
        <Modal
          visible={connectingWallet}
          footer={null}
          title="连接钱包"
          onCancel={() => setConnectingWallet(false)}
        >
          <ConnectWallet wallet={WalletType.MetaMask}>MetaMask</ConnectWallet>
        </Modal>
      </Switch>
    </Router>
  );
}

// FIXME(meow) STOP LITTERING EVERYWHERE!
const client = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/autarkxu/dodo-nft",
  cache: new InMemoryCache(),
});

export default function Root() {
  const theme = getTheme("light");
  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <WithNotifArea>
            <WithExecutionDialog>
              <RootInner />
            </WithExecutionDialog>
          </WithNotifArea>
        </ThemeProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
}
