import { AnyAction } from "redux";
import { currentLanguage } from "../i18n";

export type State = {
  language: string;
};

const DEFAULT_STATE: State = {
  language: currentLanguage(),
};

export default function settings(
  state = DEFAULT_STATE,
  action: AnyAction
): State {
  if (action.type === "SET_LANGUAGE")
    return {
      ...state,
      language: action.payload.language,
    };

  return state;
}
