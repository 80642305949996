import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Container,
  DateTip,
  OfficialSection,
  StartDateSection,
  Time,
  TimeUnit,
  Title,
} from "./Card.styled";

type Props = {
  id: string;
  title: string;
  backgroundImage: string;
  logoImage: string;
  officialName: string;
  startDate: string;
  endDate: string;
};

export function Card({
  id,
  title,
  backgroundImage,
  logoImage,
  officialName,
  startDate,
  endDate,
}: Props) {
  const his = useHistory();
  const { t } = useTranslation();
  const tz = moment.tz.guess();
  const [now, setNow] = useState(moment());
  const start = moment(startDate);
  const end = moment(endDate);
  const result = useMemo(() => {
    if (now.isBetween(start, end)) {
      const dur = (end.valueOf() - now.valueOf()) / 1000;
      const h = Math.floor(dur / (60 * 60)).toFixed();
      const m = Math.floor((dur % (60 * 60)) / 60).toFixed();
      const s = ((dur % (60 * 60)) % 60).toFixed();
      return {
        h: h.padStart(2, "0"),
        m: m.padStart(2, "0"),
        s: s.padStart(2, "0"),
      };
    }
    return undefined;
  }, [start, end, now]);
  useEffect(() => {
    const timer = window.setInterval(() => {
      setNow(moment());
    }, 1000);
    return () => {
      timer && window.clearInterval(timer);
    };
  }, []);
  return (
    <Container
      style={{
        background: `linear-gradient(180deg, rgba(26, 26, 27, 0) 0%, rgba(26, 26, 27, 0.8) 100%),center / cover no-repeat url(${backgroundImage})`,
      }}
      onClick={() => his.push(`/mystery/${id}`)}
    >
      <Title>{t(title)}</Title>

      <OfficialSection>
        <div
          className="logo"
          style={{
            background: `#ffd43d center / cover no-repeat url(${logoImage})`,
          }}
        ></div>
        <div className="name">
          {t("old-drop.main.official-auth", { officialName })}
        </div>
      </OfficialSection>

      <StartDateSection>
        <div>
          {now.isBefore(end)
            ? t("old-drop.main.start-date", {
                startDate: start.tz(tz).format("MM/DD HH:mm"),
              })
            : t("old-drop.main.end")}
        </div>

        {result && (
          <DateTip>
            <Time>{result.h}</Time>
            <TimeUnit>H</TimeUnit>
            <Time>{result.m}</Time>
            <TimeUnit>M</TimeUnit>
            <Time>{result.s}</Time>
            <TimeUnit style={{ marginRight: "0px" }}>S</TimeUnit>
          </DateTip>
        )}
      </StartDateSection>
    </Container>
  );
}
