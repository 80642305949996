import {
  approve,
  BIG_ALLOWANCE,
  getCurrentAccount,
  getCurrentChainId,
  getEstimateGas,
  sendTransaction,
  WatchResult,
  watchTransaction,
  isETHOrBSCChain,
} from "dodo-wallet";
import { OrderedMap } from "immutable";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Web3 from "web3";
import { ReactComponent as ConfirmedImg } from "../images/submission-confirmed.svg";
import { ReactComponent as PendingImg } from "../images/submission-pending.svg";
import { openEtherscanPage } from "../utils/network";
// import { logSubmissionError } from '../../lib/mixpanel';
// import { toastFailed } from '../../lib/toast';
import { dbGet, dbSet } from "../utils/db";
import { renderSubtitle, renderTitle } from "../utils/render";
import ActionButton, { ButtonVariant } from "../ActionButton/ActionButton";
import { useNormalizedGasPrice } from "../utils/hooks";
import Confirm from "../Dialog/Confirm";
import Dialog from "../Dialog/Dialog";
import { NotifVariant, useNotifs } from "../Notif";
import { OpCode, Step as StepSpec } from "./spec";
import { EtherTokenWithChainId, PERSISTENT_TX_HISTORY } from "../constants";

export enum ExecutionResult {
  // User canceled the op
  Canceled = "canceled",
  // Op failed on chain
  Failed = "failed",
  // Op confirmed on chain
  Success = "success",

  // Op submitted on chain
  // Only when called with early return mode enabled
  Submitted = "submitted",
}

export enum State {
  Running,
  Success,
  Failed,
}

type Metadata = Record<string, any>;
type TextUpdater = (Request) => null | { brief: string; subtitle?: string };

export type ExecutionCtx = {
  /**
   * Execute an on-chain operation
   * @param breif: TX title. e.g.: "Swap"
   * @param spec: TX specification.
   * @param subtitle: Additional hint text. e.g.: "10 USDT to 10 USDC"
   * @param early: When given, the returned promise resolves when user confirmed in their wallet.
   * @param metadata
   *   Useful if the caller wants to track the tx by itself. Also see: `useInflights`
   */
  execute: (
    brief: string,
    spec: StepSpec,
    subtitle?: string,
    early?: boolean,
    metadata?: Metadata
  ) => Promise<ExecutionResult>;

  /**
   * Clear all history
   */
  clear: () => void;

  /**
   * Update request text
   */
  updateText: (upd: TextUpdater) => void;

  /**
   * All order history
   * uuid -> requst + state
   */
  requests: OrderedMap<Request, State>;
};

export const ExecutionContext = React.createContext<ExecutionCtx>({
  execute: () => Promise.resolve(ExecutionResult.Canceled),
  clear: () => {
    /* Nothing */
  },
  updateText: () => {
    /* Nothing */
  },
  requests: OrderedMap(),
});

export type Request = {
  brief: string;
  spec: StepSpec;
  subtitle?: string;
  tx: string;
  metadata?: Metadata;
};

const ModifiedActionButton = styled(ActionButton)`
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
`;

const ExecDialogBody = styled.div`
  font-size: 20px;
  ${/* color: ${({ theme }) => theme.palette.mainText}; */ null}
  color: black;
  padding: 20px;
  text-align: center;

  svg {
    margin-bottom: 10px;
  }
`;

const ExecDialogHint = styled.div`
  font-size: 16px;
  ${/* color: ${({ theme }) => theme.palette.lightText}; */ null}
  color: black;
`;

const Rotate = styled.div`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  > * {
    animation: rotation 2s infinite linear;
  }
`;

type ConfirmContinuation = {
  cont: (confirmed: boolean) => void;
};

export const WithExecutionDialog = React.memo(
  ({ children }: { children: React.ReactNode }): React.ReactElement => {
    const { t } = useTranslation();

    const chainId = useSelector(getCurrentChainId);
    const account = useSelector(getCurrentAccount);
    const EtherTokenSymbol = useMemo(
      () => EtherTokenWithChainId[chainId]?.symbol,
      [chainId]
    );

    // Dialog status
    const [showing, setShowing] = useState<{
      brief: string;
      subtitle?: string;
      spec: StepSpec;
    } | null>(null);
    const [showingDone, setShowingDone] = useState(false);

    // Request data + metadata
    const initRequests: [Request, State][] = account
      ? dbGet({
          path: `${chainId}.${PERSISTENT_TX_HISTORY}.${account.toLowerCase()}`,
          defaultValue: [],
        })
      : [];
    const [requests, setRequests] = useState(
      OrderedMap<string, [Request, State]>(
        initRequests.map((tuple) => [uuidv4(), tuple])
      )
    );
    const extRequests = useMemo(
      () => requests.mapEntries(([, [req, state]]) => [req, state]),
      [requests]
    );

    // Alert confirm if there is ongoing tx
    const [confirming, setConfirming] = useState<ConfirmContinuation | null>(
      null
    );
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const hasInflight = useMemo(
      () => requests.filter(([, v]) => v === State.Running).size > 0,
      [requests]
    );
    const hasInflightRef = useRef(hasInflight);
    useEffect(() => {
      hasInflightRef.current = hasInflight;
    }, [hasInflight]);

    const notifs = useNotifs();

    const registerDone = useCallback(
      (id: string, ret: WatchResult) => {
        const state = ret === true ? State.Success : State.Failed;
        const { isBSC } = isETHOrBSCChain(chainId);

        setRequests((cur) => {
          const pair = cur.get(id);
          if (!pair) return cur;

          const [request] = pair;

          notifs.show({
            variant: ret ? NotifVariant.Success : NotifVariant.Failure,
            title: renderTitle(request.brief, t),
            link: {
              text: isBSC
                ? t("common.viewOnBscScan")
                : t("common.viewOnEtherscan"),
              onClick: () => openEtherscanPage(`tx/${request.tx}`, chainId),
            },
          });

          return cur.set(id, [request, state]);
        });
      },
      [notifs, chainId, t]
    );

    // On startup / account change, restore all tx watcher
    useEffect(() => {
      const loaded: [Request, State][] = account
        ? dbGet({
            path: `${chainId}.${PERSISTENT_TX_HISTORY}.${account.toLowerCase()}`,
            defaultValue: [],
          })
        : [];
      const mapped = loaded.map((tuple: [Request, State]): [
        string,
        [Request, State]
      ] => [uuidv4(), tuple]);
      setRequests(OrderedMap<string, [Request, State]>(mapped));

      // TODO(meow): This can trigger multiple watch simutainously. Is this a concern?
      for (const [id, [k, v]] of mapped) {
        if (v !== State.Running) continue;
        watchTransaction(k.tx, account ?? undefined).then((ret) => {
          registerDone(id, ret);
        });
      }
    }, [account, registerDone]);

    useEffect(() => {
      if (!account) return;
      const value: [Request, State][] = Array.from(requests.values());
      dbSet({
        path: `${chainId}.${PERSISTENT_TX_HISTORY}.${account.toLowerCase()}`,
        value,
      });
    }, [requests]);

    // Configurations
    const normalizedGasPrice = useNormalizedGasPrice();

    const handler = useCallback(
      async (
        brief: string,
        spec: StepSpec,
        subtitle?: string,
        early = false,
        metadata?: Metadata
      ) => {
        // TODO: check if confirming is not undefined
        if (hasInflightRef.current) {
          const ret = await new Promise<boolean>((cont) => {
            // TODO: Can we actually keep an function in a state?
            setConfirming({ cont });
          });
          setConfirming(null);

          if (!ret) return ExecutionResult.Canceled;
        }

        console.log("[Submission] Execute: ", brief, spec, subtitle, early);

        setShowing({ spec, brief, subtitle });
        setShowingDone(false);
        let tx: string;
        if (!account)
          throw new Error(
            "Cannot execute step when the wallet is disconnected"
          );
        try {
          if (spec.opcode === OpCode.Approval) {
            const addr =
              typeof spec.token === "string" ? spec.token : spec.token.address;
            tx = await approve(
              addr,
              account,
              spec.contract,
              spec.amt || BIG_ALLOWANCE
            );
          } else if (spec.opcode === OpCode.TX) {
            // Sanity check
            if (spec.to === "") throw new Error("Submission: malformed to");
            if (spec.data.length === 0)
              throw new Error("Submission: malformed data");
            if (spec.data.indexOf("0x") === 0 && spec.data.length <= 2)
              throw new Error("Submission: malformed data");

            // Prepare gas, etc...
            const params: any = {
              value: spec.value,
              data: spec.data,
              to: spec.to,
              from: account,
              gasPrice: spec.gasPrice ?? normalizedGasPrice,
              gasLimit: spec.gasLimit ?? -1,
            };

            if (params.gasPrice) {
              params.gasPrice = Web3.utils.toHex(params.gasPrice);
            }

            if (params.gasLimit <= 0) {
              const gasLimit = await getEstimateGas(params);
              if (gasLimit === null)
                throw new Error(t("common.toast.getGasLimitError"));
              params.gasLimit = gasLimit;
            }

            if (params.gasLimit) {
              params.gas = Web3.utils.toHex(params.gasLimit);
              params.gasLimit = Web3.utils.toHex(params.gasLimit);
            }

            tx = await sendTransaction(params);
            if (!tx) throw new Error(`Unexpected tx: ${tx}`);
          } else {
            throw new Error(
              `Op ${(spec as { opcode: number }).opcode} not implemented!`
            );
          }
        } catch (e) {
          setShowing(null);
          if (e.message && !e.message.includes("User denied")) {
            setErrorMessage(e.message);
            // logSubmissionError(e.message);
            notifs.show({
              variant: NotifVariant.Failure,
              title: e.message,
              temporary: true,
            });
          }
          return ExecutionResult.Canceled;
        }

        setShowingDone(true);

        const request: Request = {
          brief,
          spec,
          tx,
          subtitle,
          metadata,
        };

        const id = uuidv4();
        setRequests((cur) => cur.set(id, [request, State.Running]));

        if (early) {
          watchTransaction(tx, account).then((ret) => {
            registerDone(id, ret);
          });
          return ExecutionResult.Submitted;
        }

        const result = await watchTransaction(tx, account);
        registerDone(id, result);
        if (result === true) {
          return ExecutionResult.Success;
        } else {
          return ExecutionResult.Failed;
        }
      },
      [account, normalizedGasPrice, notifs]
    );

    const clear = useCallback(() => setRequests(OrderedMap()), []);

    const updateText = useCallback((upd: TextUpdater) => {
      setRequests((reqs) =>
        reqs.map(([req, state]) => {
          const updated = upd(req);
          if (!updated) return [req, state];
          const nreq = {
            ...req,
            brief: updated.brief,
            subtitle: updated.subtitle,
          };
          return [nreq, state];
        })
      );
    }, []);

    const ctxVal = useMemo(
      () => ({
        execute: handler,
        clear,
        updateText,
        requests: extRequests,
      }),
      [handler, extRequests, clear, updateText]
    );

    const closeShowing = useCallback(() => {
      setShowing(null);
    }, []);

    const isInsufficientError = useMemo(
      () => errorMessage?.includes("insufficient"),
      [errorMessage]
    );

    return (
      <ExecutionContext.Provider value={ctxVal}>
        {children}
        {/* TODO(meow): popover  */}
        <Dialog
          on={showing !== null}
          title={renderTitle(showing?.brief, t)}
          minititle={true}
          slim={true}
          onClose={showingDone ? closeShowing : undefined}
        >
          {showingDone ? (
            <>
              <ExecDialogBody>
                <div>
                  <ConfirmedImg />
                </div>
                {renderTitle(showing?.brief, t)}
                {t("submission.dialog.suffix")}
                <ExecDialogHint>
                  {renderSubtitle(showing?.subtitle, t)}
                </ExecDialogHint>
              </ExecDialogBody>
              <ModifiedActionButton
                variant={ButtonVariant.Default}
                onClick={() => setShowing(null)}
              >
                {t("common.dialog.done")}
              </ModifiedActionButton>
            </>
          ) : (
            <ExecDialogBody>
              <Rotate>
                <PendingImg />
              </Rotate>
              {t("submission.dialog.pending") ?? "Pending..."}
            </ExecDialogBody>
          )}
        </Dialog>

        <Confirm
          on={confirming !== null}
          onChoose={confirming?.cont ?? undefined}
          title={t("submission.multi-confirm")}
          hint={t("submission.multi-confirm-hint")}
        />

        <Confirm
          on={errorMessage !== null}
          onChoose={() => setErrorMessage(null)}
          title={
            isInsufficientError
              ? t("submission.error-message.insufficient.title", {
                  EtherTokenSymbol,
                })
              : t("submission.error-message.unknown.title")
          }
          hint={
            isInsufficientError
              ? t("submission.error-message.insufficient.hint", {
                  EtherTokenSymbol,
                })
              : t("submission.error-message.unknown.hint")
          }
        />
      </ExecutionContext.Provider>
    );
  }
);

WithExecutionDialog.displayName = "WithExecutionDialog";

/**
 * Get the submission context
 */
export function useSubmission() {
  return useContext(ExecutionContext);
}

/**
 * Get a list of inflight requests
 */
export function useInflights() {
  const { requests } = useSubmission();
  return requests.filter((v) => v === State.Running);
}
