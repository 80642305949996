import React from "react";

import styled from "styled-components";

import { WalletType, connectToWallet } from "dodo-wallet";

type Props = {
  wallet: WalletType;
  children: React.ReactNode;
};

const Wrapper = styled.button`
  height: 60px;
  border: #ccc 1px solid;
  border-radius: 30px;
  padding: 0;
  padding-right: 30px;
  line-height: 60px;
  font-size: 18px;
  display: flex;

  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

const Icon = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background: #aaa;
  margin-right: 20px;
`;

export default function ConnectWallet({ wallet, children }: Props) {
  return (
    <Wrapper onClick={() => connectToWallet(wallet)}>
      <Icon />
      {children}
    </Wrapper>
  );
}
