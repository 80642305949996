import ipfsClient from "ipfs-http-client";

const CDN_IPFS_GATEWAY = "https://ipfs.io/ipfs";
const IPFS_WRITE_GATEWAY = "https://ipfs.io";
const IPFS_GATEWAY = "https://ipfs.io/ipfs";

export function normalizeUri(uri: string, useCdn?: boolean) {
  if (uri.startsWith("http:") || uri.startsWith("https:")) {
    return uri;
  } else if (uri.startsWith("ipfs:")) {
    const pathname = uri.replace("ipfs://", "");
    return (useCdn ? CDN_IPFS_GATEWAY : IPFS_GATEWAY) + "/" + pathname;
  } else {
    throw new Error(`Unsupported protocol: ${uri}`);
  }
}

export async function getJSONUri(uri: string) {
  const resp = await fetch(normalizeUri(uri, true));
  const payload = await resp.json();
  return payload;
}

export async function newIPFSWriteClient() {
  const ipfs = await ipfsClient(IPFS_WRITE_GATEWAY as any);
  return ipfs;
}
